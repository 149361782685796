<template>
  <div>
    <v-container fluid>
      <v-tabs
          fixed-tabs
          bg-color=""
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
      >
        <v-tab @click="details = true"> user details</v-tab>
        <v-tab @click="details = false"> linked files</v-tab>
      </v-tabs>
      <div v-if="details">
        <v-card
            class="ma-12"
            width="400"
            title="Id"
            :text="chosen_user.identificationCode"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="First Name"
            :text="chosen_user.firstName"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Last Name"
            :text="chosen_user.lastName"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Email"
            :text="chosen_user.email"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Phone Number"
            :text="chosen_user.phoneNumber"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Cell Number"
            :text="chosen_user.cellPhoneNumber"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Address"
            :text="chosen_user.address"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Job Position"
            :text="chosen_user.jobPosition"
        ></v-card>
        <v-card
            class="ma-12"
            width="400"
            title="Accounting Code"
            :text="chosen_user.accountingCode"
        ></v-card>
        <v-card
            v-if="chosen_user.enabled"
            class="ma-12"
            width="400"
            title="Status"
            text="active"
        ></v-card>
        <v-card
            v-else
            class="ma-12"
            width="400"
            title="Status"
            text="disactive"
        ></v-card>
      </div>
      <div v-else>
        <v-container fluid>
          <v-row>
            <v-col cols="3">
              <v-form ref="upload_file_form">
                <v-file-input
                    v-model="selectedFiles"
                    label="Select File"
                    multiple
                    class="adjust-label"
                    :accept="acceptedFileTypes"
                    outlined
                    show-size
                    :rules="multipleFileRequiredRule"
                ></v-file-input>
              </v-form>
            </v-col>
            <v-col cols="auto">
              <v-btn color="button_color" @click="uploadFiles">Upload</v-btn>
            </v-col>
            <v-col v-if="selectedFiles.length === 0" cols="auto"
            ><p class="mt-4">please select a file</p>
            </v-col>
          </v-row>
        </v-container>

        <v-list>
          <v-list-item v-for="(file, index) in selectedFiles" :key="index">
            <v-icon @click="remove(file)">mdi-delete</v-icon>
            {{ file.name }}
          </v-list-item>
        </v-list>

        <v-toolbar-title> all files:</v-toolbar-title>
        <v-list>
          <v-list-item v-for="(file, index) in files" :key="index">
            <v-icon @click="downLoad(file)">mdi-download</v-icon>
            {{ file.name }}
          </v-list-item>
        </v-list>
      </div>
      <Success_Modal
          :message="message"
          :success="success"
          @closeSuccessModal="success = false"
      ></Success_Modal>
      <Error_Modal
          :message="message"
          :error="error"
          @closeErrorModal="error = false"
      ></Error_Modal>
    </v-container>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import {unifiedResponseHandler, convertByteCode} from "@/utils/helpers";
import {mapGetters} from "vuex";
import {ACCEPTED_FILE_TYPES} from "../../../config/constants.js";
import {multipleFileRequiredRule} from "@/utils/rules";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "user_details_v01",
  components: {Error_Modal, Success_Modal},
  data() {
    return {
      details: true,
      selectedFiles: [],
      acceptedFileTypes: ACCEPTED_FILE_TYPES,
      success: false,
      multipleFileRequiredRule: multipleFileRequiredRule,
      files: [],
      message: null,
      error: false,
    };
  },
  methods: {
    async uploadFiles() {
      let {valid} = await this.$refs.upload_file_form.validate();
      if (valid) {
        let response = unifiedResponseHandler(
            await userService.attachFiles(
                this.chosen_user.email,
                this.selectedFiles[0]
            )
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.selectedFiles.length = 0;
          await this.getFiles(this.chosen_user.email);
        } else {
          this.message = 'File size should be less than 5MB'
          this.error = true;
        }
      }
    },
    remove(file) {
      this.selectedFiles = this.selectedFiles.filter((item) => item !== file);
    },
    async downLoad(file) {
      this.success = await convertByteCode(file);
    },
    async getFiles(email) {
      let response = unifiedResponseHandler(
          await userService.getUserFiles(email)
      );
      if (response.success) {
        this.files = response.data;
      } else {
        this.message = response.message;
      }
    },
  },
  computed: {
    ...mapGetters(["chosen_user"]),
  },
  mounted() {
    this.getFiles(this.chosen_user.email);
  },
  watch: {
    error(newValue) {
      if (!newValue) {
        this.message = "";
      }
    },
    success(newValue) {
      if (!newValue) {
        this.message = "";
      }
    },
  },
};
</script>
