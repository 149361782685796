<template>
  <div>
    <v-container fluid style="background-color: white">
      <h5 class="ml-1">Safe Box Operation</h5>
      <v-form class="adjust-label" ref="safe_box_form">
        <v-row class="mt-4">
          <v-col md="4" sm="4" xs="12">
            <div class="custom-height">
              <v-text-field
                  v-model="description"
                  label="Description"
                  @input="checkDescriptionLength"
              >
              </v-text-field>
            </div>
            <v-tab
                @click="secondaryThirdPartyListModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
            >select
            </v-tab>
            <v-text-field
                label="Payer/Payee"
                v-model="secondaryThirdPartyFullName"
                @keyup.backspace="clearSecondaryThirdParty"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
            <v-text-field
                class="mt-6 custom-icon-size"
                v-maska:[moneyOption]
                label="Withdraw Amt"
                prepend-inner-icon="$safeBoxWithdraw"
                v-model="withdrawalAmount"
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="4" xs="12">
            <div class="custom-height">
              <v-text-field
                  label="Ref Number"
                  v-model="refNumber">
              </v-text-field>
            </div>

            <v-tab
                @click="safeBoxThirdPartyModal = true"
                prepend-icon="mdi-magnify"
                color="button_color"
                :rules="requiredRule"
            >select
            </v-tab>
            <v-text-field
                label="Select Third Party"
                v-model="safeBoxThirdPartyFullName"
                @keyup.backspace="clearSafeBoxThirdParty"
                @keydown="handleKeyDown"
                :rules="requiredRule"
            ></v-text-field>
            <v-text-field
                class="mt-6 custom-icon-size"
                v-maska:[moneyOption]
                label="Deposit Amt"
                prepend-inner-icon="$safeBoxDeposit"
                v-model="depositAmount"
            ></v-text-field>
          </v-col>
          <v-col md="4" sm="4" xs="12">
            <div class="custom-height">
              <label for="date-picker" style="color: black">Date</label>
              <VueDatePicker
                  auto-apply="true"
                  id="date-picker"
                  v-model="date"
                  :show-timepicker="false"
              ></VueDatePicker>
            </div>
            <v-autocomplete
                class="mt-6"
                label="Select Currency"
                v-model="selectedCurrency"
                :items="currencies"
                item-title="name"
                item-value="name"
                filled
                return-object
                :rules="requiredRule"
            ></v-autocomplete>


            <v-btn class="ml-10 mt-10" color="button_color" @click="generateSafeBox()">Do Safe Box Operation</v-btn>
          </v-col>
        </v-row>`
      </v-form>
      <v-dialog v-model="secondaryThirdPartyListModal" width="auto">
        <third_party_list_modal
            thirdPartyType="SECONDARY_THIRDPARTY"
        ></third_party_list_modal>
      </v-dialog>

      <v-dialog v-model="safeBoxThirdPartyModal" width="auto">
        <third_party_list_modal
            third-party-type="SAFE_BOX"
        ></third_party_list_modal>
      </v-dialog>

    </v-container>

    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>

    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>

  </div>
</template>
<script>
import third_party_list_modal from "../../Modals/Third_Party_List_Modal_V01.vue";
import {
  extractNumber,
  formatCurrencyInfo, formatNumber,
  handleKeyDown,
  unifiedResponseHandler
} from "@/utils/helpers";
import {requiredRule} from "@/utils/rules";
import emitter from "@/plugins/mitt";
import {MoneyOption, PercentageOption,} from "@/utils/maska_options";
import CurrencyService from "@/services/currency.service";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import safeBoxOperationService from "@/services/SafeBoxOperationService";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";

export default {
  name: "money_transfer_v01",
  components: {
    Success_Modal,
    Error_Modal,
    third_party_list_modal,
  },
  data() {
    return {
      percentageOption: PercentageOption,
      loading: false,
      currencies: [],
      error: false,
      success: false,
      message: null,
      requiredRule: requiredRule,
      selectedCurrency: null,
      refNumber: null,
      addItem: null,
      removeItem: null,
      withdrawalAmount: null,
      depositAmount: null,
      secondaryThirdParty: null,
      secondaryThirdPartyListModal: false,
      safeBoxThirdPartyModal: false,
      safeBoxThirdParty: null,
      safeBoxAccount: null,
      description: null,
      date: null,
    };
  },
  methods: {
    handleKeyDown(event) {
      handleKeyDown(event);
    },
    checkDescriptionLength() {
      if (this.description.length > 255) {
        this.description = this.description.slice(0, 255);
        this.error = true;
        this.message = "Description is too long (max 255 characters). :)";
      }
    },
    formatNumber,
    clearSecondaryThirdParty() {
      this.secondaryThirdParty = null;
    },
    clearSafeBoxThirdParty() {
      this.safeBoxThirdParty = null;
      this.safeBoxAccount = null;
    },
    async getCurrencies() {
      let response = unifiedResponseHandler(await CurrencyService.getCurrencies());
      if (response.success) {
        console.log("response for currencies", response.data);
        this.currencies = formatCurrencyInfo(response.data);
      } else {
        this.message = response.message;
        this.error = true;
      }
    },
    async generateSafeBox() {
      let {valid} = await this.$refs.safe_box_form.validate();
      if (valid) {
        let form = {
          currencyId: this.selectedCurrency.id,
          referenceNumber: this.refNumber,
          withdrawalAmount: extractNumber(this.withdrawalAmount),
          depositAmount: extractNumber(this.depositAmount),
          secondaryThirdPartyId: this.secondaryThirdPartyId,
          safeBoxThirdPartyId: this.safeBoxThirdParty.id,
          description: this.description,
          occurrenceDate: this.date,
        };

        let response = unifiedResponseHandler(await safeBoxOperationService.executeSafeBoxOperation(form));
        if (response.success) {
          this.success = true;
          this.message = response.message;
          this.clearForm();
        } else {
          this.message = response.message;
          this.error = true;
        }
      }
    },
    clearForm() {
      this.selectedCurrency = null;
      this.refNumber = null;
      this.addItem = null;
      this.removeItem = null;
      this.withdrawalAmount = null;
      this.depositAmount = null;
      this.secondaryThirdParty = null;
      this.safeBoxThirdParty = null;
      this.description = null;
      this.date = null;
    }
  },
  async mounted() {
    emitter.on("new_third_party", (value) => {
      if (value.thirdPartyType === "SECONDARY_THIRDPARTY") {
        this.secondaryThirdParty = value;
        this.secondaryThirdPartyListModal = false;
      } else if (value.thirdPartyType === "SAFE_BOX") {
        this.safeBoxThirdParty = value;
        this.safeBoxThirdPartyModal = false;
      }
    });

    await this.getCurrencies();
  },

  computed: {
    moneyOption() {
      if (this.selectedCurrency) {
        return MoneyOption(this.selectedCurrency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    secondaryThirdPartyFullName() {
      if (this.secondaryThirdParty) {
        return this.secondaryThirdParty.fullName;
      } else {
        return null;
      }
    },
    safeBoxThirdPartyFullName() {
      if (this.safeBoxThirdParty) {
        return this.safeBoxThirdParty.fullName;
      } else {
        return null;
      }
    },
    secondaryThirdPartyId() {
      if (this.secondaryThirdParty) {
        return this.secondaryThirdParty.id;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.custom-height {
  height: 78px; /* Specify your desired height */
}

::v-deep(.custom-icon-size i.v-icon.notranslate.v-theme--light_mode.v-icon--size-default) {
  /* Your styles here */
  height: 48px;
  width: 48px;
  margin-right: 5px;
}
</style>