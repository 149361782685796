<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3" class="mt-15">
        <v-card
            class="mx-auto"
            max-width="344"
            color="side_color"
            @click="setPage('my_dashboard')"
        >
          <v-card-text>
            <div>{{ $t("My Dashboard") }}</div>
            <p class="text-h5 text--primary">{{ $t("My Tasks") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('my_dashboard')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card
            class="mx-auto mt-8"
            max-width="344"
            color="side_color"
            @click="setPage('my_dashboard')"
        >
          <v-card-text>
            <div>{{ $t("My Dashboard") }}</div>
            <p class="text-h5 text--primary">{{ $t("My Notes") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('my_dashboard')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <div
            style="
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 2rem;
          "
        >
          <h2>{{ $t('my dashboard') }}</h2>
          <v-img
              :width="600"
              aspect-ratio="16/9"
              cover
              :src="dashboardImage"
          ></v-img>
        </div>
      </v-col>
      <v-col cols="12" md="3" class="mt-15">
        <v-card
            class="mx-auto"
            max-width="344"
            color="side_color"
            @click="setPage('my_dashboard')"
        >
          <v-card-text>
            <div>{{ $t("My Dashboard") }}</div>
            <p class="text-h5 text--primary">{{ $t("My Reminders") }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn
                variant="text"
                color="button_color"
                @click="setPage('my_dashboard')"
            >
              Go To
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {setPage} from "@/utils/helpers";
import dashboardImage from "@/assets/img/homeImg/dashboard-g.svg?url"

export default {
  name: "home_main_v01",
  methods: {
    setPage(page) {
      setPage(page);
    },
  },
  data() {
    return {
      dashboardImage
    }
  }
};
</script>
