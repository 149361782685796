<template>
  <v-container fluid v-if="!broken_page">
    <h3 class="font-weight-medium">{{ this.thirdPartyType }} Entries</h3>
    <v-row class="mb-2">
      <v-col>
        <div class="search-bar">
          <v-text-field
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
              @keyup.enter="searchForItem(searchValue)"
          >
            <template v-slot:prepend-inner>
              <v-icon
                  icon="mdi-magnify"
                  variant="tonal"
                  color="button_color"
                  class="mr-3"
              >
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
            color="button_color"
            @click="QTrusteeEntriesModal = true"
            icon="mdi-help"
            class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="toExcelTransactionsDetails" :formats="formats" type="xlsx" name="entries.xlsx">
            <v-icon>mdi-export</v-icon>
          </download-excel>
        </v-btn>
      </v-col>
    </v-row>
    <EasyDataTable
        :headers="headers"
        :items="items"
        v-model:serverOptions="serverOptions"
        :server-items-length="totalItems"
        :loading="loading"
        body-text-direction="center"
        header-text-direction="center"
        class="adjust-label custom-data-table"
        border-cell
    >
      <template #pagination="{}">
        <v-btn
            density="compact"
            class="ma-4"
            :disabled="serverOptions.page === 1"
            @click="goToPrevPage"
        >prev page
        </v-btn>
        <v-btn
            density="compact"
            class="ma-4"
            :disabled="serverOptions.page === numberOfPages"
            @click="goToNextPage"
        >next page
        </v-btn>
      </template>
      <template #item-debit="{ debit }">
        <div class="currency-amount">
          <span class="currency">{{ splitCurrencyValue(debit).currency }}</span>
          <span class="text-right">{{ splitCurrencyValue(debit).value }}</span>
        </div>
      </template>
      <template #item-credit="{ credit }">
        <div class="currency-amount">
          <span class="currency">{{
              splitCurrencyValue(credit).currency
            }}</span>
          <span class="text-right">{{ splitCurrencyValue(credit).value }}</span>
        </div>
      </template>
      <template #item-accountBalance="{ accountBalance }">
        <div class="currency-amount">
          <span class="currency">{{
              splitCurrencyValue(accountBalance).currency
            }}</span>
          <span class="text-right">{{
              splitCurrencyValue(accountBalance).value
            }}</span>
        </div>
      </template>
      <template #item-eventId="item">
        <v-icon @click="showRequestInfo(item)">
          <span class="modal-icon">{{ item.eventId }}</span>
        </v-icon>
      </template>
    </EasyDataTable>
    <v-dialog v-model="QTrusteeEntriesModal" width="auto">
      <Q_TrusteeEntries_Modal_V01></Q_TrusteeEntries_Modal_V01>
    </v-dialog>
    <v-dialog v-model="showTransferInfo" width="auto">
      <money_transfer_request_modal
          v-if="transferInfoType === 'money'"
      ></money_transfer_request_modal>
      <internal_Transfer_Modal_V01
          v-if="transferInfoType === 'internal'"
      ></internal_Transfer_Modal_V01>
      <crypto_Deal_Modal_V01
          v-if="transferInfoType === 'crypto'"
      ></crypto_Deal_Modal_V01>
      <SafeBox_Modal_V01
          v-if="transferInfoType === 'safebox'"
      ></SafeBox_Modal_V01>
      <Manual_Transaction_Modal_V01
          v-if="transferInfoType === 'manual'"
      ></Manual_Transaction_Modal_V01>
    </v-dialog>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <Error_Modal
      :error="error"
      :message="message"
      @closeErrorModal="error = false"
  ></Error_Modal>
</template>

<script>
import Q_TrusteeEntries_Modal_V01 from "@/components/ModalQ/Q_TrusteeEntries_Modal_V01.vue";
import transactionService from "@/services/transaction.service";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import {getEventTypeDisplay} from "@/utils/NewFunctions/getEventTypeDisplay";
import getStatusDisplay from "@/utils/NewFunctions/getStatusDisplay";
import money_transfer_request_modal from "@/components/Modals/transfer/Money_Transfer_Request_Modal_V01.vue";
import crypto_Deal_Modal_V01 from "@/components/Modals/transfer/Crypto_Deal_Modal_V01.vue";
import internal_Transfer_Modal_V01 from "@/components/Modals/transfer/Internal_Transfer_Modal_V01.vue";
import money_transfer_requestService from "@/services/money_transfer_request.service";
import {formatNumber, unifiedResponseHandler} from "@/utils/helpers";
import internal_transferService from "@/services/internal_transfer.service";
import cryptoService from "@/services/crypto.service";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import safeBoxOperationService from "@/services/SafeBoxOperationService";
import SafeBox_Modal_V01 from "../Modals/transfer/SafeBox_Modal_V01.vue";
import manualTransferService from "@/services/manual_transfer.service";
import Manual_Transaction_Modal_V01 from "../Modals/transfer/Manual_Transaction_Modal_V01.vue";

export default {
  name: "customer_entries",
  props: {
    thirdPartyType: String,
  },
  components: {
    Error_Modal,
    money_transfer_request_modal,
    Q_TrusteeEntries_Modal_V01,
    Broken_Page_V01,
    crypto_Deal_Modal_V01,
    internal_Transfer_Modal_V01,
    SafeBox_Modal_V01,
    Manual_Transaction_Modal_V01
  },
  data() {
    return {
      formats: {
        H: '#,##0.00',
        G: '#,##0.00',
        F: '#,##0.00',
        D: 'm/d/yy',
        E: 'm/d/yy',
      },
      headers: [
        {text: "Status", value: "transactionStatus"},
        {text: "Account Number", value: "accountNumber"},
        {text: "Coffer Name", value: "cofferName"},
        {text: "Debit", value: "debit"},
        {text: "Credit", value: "credit"},
        {text: "Account Balance", value: "accountBalance"},
        {text: "Event Type", value: "eventType"},
        {text: "Event Id", value: "eventId"},
        {text: "Created At", value: "createdAt"},
      ],
      items: [],
      loading: false,
      showTransferInfo: false,
      transferInfoType: null,
      searchValue: null,
      message: null,
      broken_page: false,
      success: false,
      error: false,
      transactions: [],
      totalItems: 0,
      accountCofferName: null,
      debounceTimer: null,
      serverOptions: {
        page: 1,
        rowsPerPage: 25,
      },
      QTrusteeEntriesModal: false,
      numericalSearchField: null,
    };
  },

  methods: {
    splitCurrencyValue(currencyString) {
      const parts = currencyString.split(" ");
      return {
        currency: parts[0],
        value: parts[1],
      };
    },
    goToPrevPage() {
      this.serverOptions.page = this.serverOptions.page - 1;
    },
    goToNextPage() {
      this.serverOptions.page = this.serverOptions.page + 1;
    },
    async searchForItem(item) {
      item = item.replace(/^\s+|\s+$/g, '');
      const isNumber = /^\d+(\.\d+)?$/.test(item);
      const isString = /^[a-zA-Z ]+$/.test(item);
      if (isNumber) {
        this.numericalSearchField = item;
        this.accountCofferName = null;
      } else if (isString) {
        this.accountCofferName = item
        this.numericalSearchField = null;
      }
      if (this.serverOptions.page === 1) {
        this.getTransactions();
      } else {
        this.serverOptions.page = 1;
      }
    },
    async showRequestInfo(item) {
      let eventType = item.eventType;
      let response;
      switch (eventType) {
        case "Cash Receive":
        case "Cash Send":
        case "Bank Receive":
        case "Bank Send":
          this.transferInfoType = "money";
          response = unifiedResponseHandler(
              await money_transfer_requestService.getById(item.eventId)
          );
          if (response.success) {
            this.$store.dispatch("setChosenRequest", response.data);
            this.showTransferInfo = true;
          } else {
            this.message = response.message;
            this.error = true;
          }
          break;
        case "Internal":
          this.transferInfoType = "internal";
          response = unifiedResponseHandler(
              await internal_transferService.getInternalTransferById(item.eventId)
          );
          if (response.success) {
            this.$store.dispatch("setChosenInternalTransfer", response.data);
            this.showTransferInfo = true;
          } else {
            this.message = response.message;
            this.error = true;
          }
          break;
        case "Crypto Deal":
          this.transferInfoType = "crypto";
          response = unifiedResponseHandler(
              await cryptoService.getCryptoDealById(item.eventId)
          );
          if (response.success) {
            this.$store.dispatch("setChosenCryptoDeal", response.data);
            this.showTransferInfo = true;
          } else {
            this.message = response.message;
            this.error = true;
          }
          break;
        case "Safe Box Operation":
          this.transferInfoType = "safebox";
          response = unifiedResponseHandler(
              await safeBoxOperationService.getSafeBoxOperationById(item.eventId)
          );
          if (response.success) {
            this.$store.dispatch("setChosenSafeBox", response.data);
            this.showTransferInfo = true;
          } else {
            this.message = response.message;
            this.error = true;
          }
          break;
        case "Manual":
          this.transferInfoType = "manual";
          response = unifiedResponseHandler(
              await manualTransferService.getManualTransactionById(item.eventId)
          );
          if (response.success) {
            this.$store.dispatch("setChosenManualTransaction", response.data);
            this.showTransferInfo = true;
          } else {
            this.message = response.message;
            this.error = true;
          }
          break;
      }
    },
    async getTransactions() {
      this.loading = true;
      const {page, rowsPerPage} = this.serverOptions;
      const {thirdPartyType, accountCofferName, numericalSearchField} = this;

      let response;
      switch (thirdPartyType) {
        case "Customer":
          response = await transactionService.getTransactionsEntries(
              page - 1,
              rowsPerPage,
              "CUSTOMER",
              accountCofferName,
              numericalSearchField
          );
          break;
        case "Trustee":
          response = await transactionService.getTransactionsEntries(
              page - 1,
              rowsPerPage,
              "TRUSTEE",
              accountCofferName,
              numericalSearchField
          );
          break;
        case "Safebox":
          response = await transactionService.getTransactionsEntries(
              page - 1,
              rowsPerPage,
              "SAFE_BOX",
              accountCofferName,
              numericalSearchField
          );
          break;
        case "Payer/Payee":
          response = await transactionService.getTransactionsEntries(
              page - 1,
              rowsPerPage,
              "SECONDARY_THIRDPARTY",
              accountCofferName,
              numericalSearchField
          );
          break;
        default:
          console.log(thirdPartyType);
          break;
      }

      if (response.status === 200) {
        const {data} = response;
        this.transactions = data.data;
        this.totalItems = data.totalElements;
        this.items = this.transactionsDetails;
      }

      this.loading = false;
    }
  },

  async mounted() {
    await this.getTransactions();
  },
  computed: {
    transactionsDetails() {
      return this.transactions.map((transaction) => {
        let transactionTypeDisplay;
        transactionTypeDisplay = getEventTypeDisplay(transaction);
        let transactionStatus = getStatusDisplay(transaction.transactionStatus);
        return {
          id: transaction.id,
          credit:
              transaction.accountDirection === "CREDIT"
                  ? transaction.currency + " " + formatNumber(transaction.amount)
                  : "",
          debit:
              transaction.accountDirection === "DEBIT"
                  ? transaction.currency + " " + formatNumber(transaction.amount)
                  : "",
          transactionStatus: transactionStatus,
          accountId: transaction.account.id,
          accountNumber: transaction.thirdParty.accountNumber,
          cofferName: transaction.account.cofferName,
          ledgerCode: transaction.ledgerCode,
          amount: transaction.currency + " " + formatNumber(transaction.amount),
          direction: transaction.accountDirection,
          eventId: transaction.eventId,
          eventType: transactionTypeDisplay,
          createdAt: new Date(transaction.createdAt)
              .toISOString()
              .split("T")[0],
          accountBalance:
              transaction.currency + " " + formatNumber(transaction.accountBalance),
        };
      });
    },

    toExcelTransactionsDetails() {
      return this.transactions.map((transaction) => {
        let transactionTypeDisplay = getEventTypeDisplay(transaction);
        return {
          cofferName: transaction.account.cofferName,
          eventId: transaction.eventId,
          eventType: transactionTypeDisplay,
          createdAt: new Date(transaction.createdAt).toISOString().split("T")[0],
          modifiedAt: new Date(transaction.modifiedAt).toISOString().split("T")[0],
          debit: transaction.accountDirection === "DEBIT" ? transaction.amount : "",
          credit: transaction.accountDirection === "CREDIT" ? transaction.amount : "",
          accountBalance: transaction.accountBalance,
        };
      });
    },
    numberOfPages() {
      return Math.ceil(this.totalItems / this.serverOptions.rowsPerPage);
    },
  },
  watch: {
      searchValue(newVal) {
        if (newVal === "") {
          this.accountCofferName = "";
          this.serverOptions.page = 1;
          this.getBillPayment();
        } else {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(() => {
            this.searchForItem(newVal);
          }, 500);
        }
      },
    serverOptions: {
      handler() {
        this.getTransactions();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.search-bar {
  display: flex;
  width: 29vw;
}
</style>
