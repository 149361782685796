<template>
    <div>
        <v-container fluid style="background-color: white; width: 500px; height: 400px; border-radius: 5px;">
            <v-form class="adjust-label" ref="checkbooks_form">
                <v-row class="mt-2">
                    <v-col cols="12" md="12" sm="12" xs="12">
                        <v-tab
                            @click="tAccountListModal = true"
                            prepend-icon="mdi-magnify"
                            color="button_color"
                            >select</v-tab
                        >

                        <v-text-field
                            label="Trustee Account"
                            v-model="tAccountName"
                            @keyup.backspace="clearTAccount"
                            @keydown="handleKeyDown"
                            :rules="requiredRule"
                        ></v-text-field>
                        <v-text-field   
                            label="Check Serial Number Start"
                            v-model="CheckSerialNumberStart"
                            :rules="requiredRuleNumber"
                        ></v-text-field>
                        <v-text-field   
                            label="Check Serial Number End"
                            v-model="CheckSerialNumberEnd"
                            :rules="requiredRuleNumber"
                        ></v-text-field>
                        <div class="d-flex top-20" style="display: flex; justify-content: end;">
                          <v-btn color="button_color" @click="createCheckbooks">{{ $t("confirm") }}</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <v-dialog v-model="tAccountListModal" width="auto">
            <list_of_accounts_modal_v01
              third-party-type="TRUSTEE"
            ></list_of_accounts_modal_v01>
        </v-dialog>
        <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>      
          </div>
          <div class="success-right">
          <span class="close-icon" @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> {{ message }}</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog>
    
    <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span class="close-icon" @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }}</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>
    </div>
</template>
<script>
import list_of_accounts_modal_v01 from "./List_Of_Accounts_Modal_V01.vue";
import { requiredRule, requiredRuleNumber } from "@/utils/rules";
import emitter from "@/plugins/mitt";
import checkbooksService from "@/services/checkbooks.service";
import {unifiedResponseHandler } from "@/utils/helpers";

export default {
    name:"checkbooks_v01",
    components:{
        list_of_accounts_modal_v01
    },
    data(){
        return {
      error: false,
      success: false,
      message: null,
      requiredRule: requiredRule,
      requiredRuleNumber: requiredRuleNumber,
      info: false,
      tAccountListModal: false,
      tAccount: null,
      CheckSerialNumberEnd: null,
      CheckSerialNumberStart: null,
        };
    },
    methods:{
       async createCheckbooks(){
        let { valid } = await this.$refs.checkbooks_form.validate();
            if (valid) {
            let form = {
                trusteeAccountId: this.tAccountId,
                checkSerialNumberEnd: this.CheckSerialNumberEnd,
                checkSerialNumberStart: this.CheckSerialNumberStart,
            };
            console.log("valid", form);
            let response = unifiedResponseHandler( await checkbooksService.createCheckbooks(form));
            console.log("Checkbook",response);
            if (response.success) {
                this.success = true;
                emitter.emit("new_checkbook", response);
                console.log("new_currency",response);
                this.message = response.data.message;
                this.tAccountId = null;
                this.tAccountName = null;
                this.tAccount = null;
                this.CheckSerialNumberEnd = null;
                this.CheckSerialNumberStart = null;
            } else {
                this.error = true;
            }
        }

    },},
    computed:{
        tAccountName() {
            if (this.tAccount) {
            return this.tAccount.cofferName;
            } else {
            return null;
            }
        },
        tAccountId() {
            if (this.tAccount) {
            return this.tAccount.id;
            } else {
            return null;
            }
        },
    },
    async mounted(){
        emitter.on("chosenAccount", (value) => {
        this.tAccount = value;
        this.tAccountListModal = false;
  });
    },
    beforeUnmount() {
        emitter.emit("new_checkbook", null);
    }
}

</script>