import {accesses} from "@/config/accesses";
import {authRole} from "./auth-header";

export const allowedSections = (sections) => {
    let secs = JSON.parse(JSON.stringify(sections));
    let role = authRole();
    if (Array.isArray(secs)) {
        for (let i = secs.length - 1; i >= 0; i--) {
            let key = secs[i];
            if (accesses[key] !== undefined) {
                let flag = false;
                if (accesses[key].includes(role)) {
                    flag = true;
                }
                if (!flag) {
                    secs.splice(i, 1);
                }
            }
        }
    } else {
        for (let key of Object.keys(secs)) {
            if (accesses[key] !== undefined) {
                if (Array.isArray(accesses[key])) {
                    if (!accesses[key].includes(role)) {
                        delete secs[key];
                    }
                } else if (typeof accesses[key] === 'object') {
                    secs[key] = secs[key].filter(subKey => {
                        if (accesses[key][subKey] === undefined) {
                            return true;
                        }
                        return accesses[key][subKey].includes(role);
                    });
                    if (secs[key].length === 0) {
                        delete secs[key];
                    }
                }
            }
        }
    }
    return secs;
};
