<template>
  <v-container fluid>
    <v-form ref="new_third_party_group_form" class="mt-4" :disabled="loading">
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-tab
              @click="thirdPartyListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
          >select
          </v-tab>
          <v-text-field
              v-model="chosenThirdPartyFullName"
              label="Selected Third Party Name"
              @keydown="handleKeyDown"
              :rules="requiredRule()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col class="mt-0 mb-0" cols="auto">
          <v-btn class="mr-4" color="button_color"
                 @click="addThirdPartyToGroup"
          >Add Third Party To Group
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <template v-if="thirdParties && thirdParties.length > 0">
      <div class="thirdPartiesList">
        <br>
        <v-card variant="outlined">
          <v-card-title class="text-h5 py-3 text-center">
            Third Parties
          </v-card-title>
          <v-table height="300px">
            <thead>
            <tr>
              <th class="text-left" v-for="header in headers" :key="header">{{ header['text'] }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="thirdParty in thirdParties" :key="thirdParty.id">
              <td>{{ thirdParty.id }}</td>
              <td>{{ thirdParty.fullName }}</td>
              <td>{{ thirdParty.thirdPartyType }}</td>
              <td>{{ thirdParty.email }}</td>
              <td>
                <v-icon @click="deleteThirdPartyFromGroup(thirdParty)">mdi-delete</v-icon>
              </td>
              <td>
                <v-icon @click="goToEdit(thirdParty)">mdi-pencil</v-icon>
              </td>
            </tr>
            </tbody>
          </v-table>
        </v-card>
      </div>
    </template>
  </v-container>

  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>
  <Confirmation_Modal
      :confirmation="confirmation"
      @DoConfirmation="getConfirmation()"
      @closeConfirmationModal="confirmation = false"
  ></Confirmation_Modal>

  <v-dialog v-model="thirdPartyListModal" width="auto">
    <Third_party_list_modal
        thirdPartyType="ALL"
    ></Third_party_list_modal>
  </v-dialog>
</template>

<script>

import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import third_party_groupService from "@/services/third_party_group.service.js";
import {
  handleKeyDown,
  setPage,
  unifiedResponseHandler
} from "@/utils/helpers.js";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";
import Third_party_list_modal from "@/components/Modals/Third_Party_List_Modal_V01.vue";
import emitter from "@/plugins/mitt.js";
import {requiredRule} from "@/utils/rules.js";

export default {
  name: "third_parties_list_in_group",
  props: ['thirdPartyGroup'],
  components: {Third_party_list_modal, Confirmation_Modal, Success_Modal, Error_Modal},

  data() {
    return {
      headers: [
        {text: "ID", value: "id"},
        {text: "Full name", value: "fullName"},
        {text: "Type", value: "thirdPartyType"},
        {text: "Email", value: "email"},
        {text: "Delete", value: "delete"},
        {text: "Edit/Details", value: "edit"},
      ],
      thirdParties: [],
      enteredId: "",
      message: "",
      success: false,
      error: false,
      loading: true,
      confirmation: false,
      confirmed: false,
      enteredThirdPartyId: "",
      thirdPartyListModal: false,
      chosenThirdParty: null
    }
  },
  methods: {
    requiredRule() {
      return requiredRule
    },
    handleKeyDown,
    goToEdit(third_party) {
      this.$store.dispatch("setChosenThirdParty", third_party);
      this.$store.dispatch("setIsEditingThirdParty", true);
      setPage("new_third_party");
    },
    async getThirdPartiesInGroup() {
      this.loading = true;
      let response = unifiedResponseHandler(await third_party_groupService.getAllThirdPartiesInGroup(this.thirdPartyGroup.id));
      if (response.success) {
        this.thirdParties = response.data;
        console.log(this.thirdParties);
        this.loading = false;
      }
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async deleteThirdPartyFromGroup(third_party) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
            await third_party_groupService.removeThirdPartyFromGroup(this.thirdPartyGroup.id, third_party.id)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          await this.getThirdPartiesInGroup();
        } else {
          this.error = true;
        }
      }
    },
    async addThirdPartyToGroup() {
      if (this.enteredThirdPartyId === "" || this.enteredThirdPartyId === null) {
        this.message = "Please enter third party id";
        this.error = true;
        return;
      }
      if (await this.$refs.new_third_party_group_form.validate()) {
        let response = unifiedResponseHandler(await third_party_groupService.addThirdPartyToGroup(this.thirdPartyGroup.id, this.enteredThirdPartyId))
        this.message = response.message
        if (response.success) {
          this.loading = false;
          this.success = true;
          await this.getThirdPartiesInGroup();
        } else {
          this.error = true;
        }
        this.chosenThirdParty = null;
      }
    },
  },
  computed: {
    chosenThirdPartyFullName() {
      return this.chosenThirdParty?.fullName || null;
    },
  },
  async mounted() {
    emitter.on("new_third_party", (value) => {
      if (this.thirdPartyListModal) {
        this.chosenThirdParty = value;
        this.enteredThirdPartyId = this.chosenThirdParty.id
        this.thirdPartyListModal = false;
      }
    });
    await this.getThirdPartiesInGroup();
  }
}
</script>

<style scoped>
</style>
