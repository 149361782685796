<template>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">SafeBox</h3>
      <v-row>
        <v-col>
          <v-text-field
            class="adjust-label search-field"
            label="Search"
            v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
            color="button_color"
            @click="NewThirdPartyList()"
            icon="mdi-plus"
            class="ml-2"
          >
          </v-btn>
          <v-btn
            color="button_color"
            @click="QSafeBox = true"
            icon="mdi-help"
            class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="safeBox"
              ><v-icon>mdi-export</v-icon></download-excel
            >
          </v-btn>
        </v-col>
      </v-row>
  
      <EasyDataTable
        :headers="headers"
        :items="safeBox"
        item-title="name"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteThirdParty(item)">mdi-delete</v-icon>
        </template>
        <template #item-creditLimit="{ creditLimit }">
          <div style="text-align: end">
            <span>{{ creditLimit }}</span>
          </div>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
    <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
    ></Error_Modal>

  <Confirmation_Modal
      :confirmation="confirmation"
      @DoConfirmation="getConfirmation()"
      @closeConfirmationModal="confirmation = false"
  ></Confirmation_Modal>
  </template>
  
  <script>
  import { requiredRule } from "@/utils/rules.js";
  import third_partyService from "@/services/third_party.service";
  import {
    setPage,
    unifiedResponseHandler,
    formatNumber,
  } from "@/utils/helpers";
  import Broken_Page_V01 from "../../Broken_Page_V01.vue";
  import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
  import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
  import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";

  export default {
    name: "safeBox_list_v01",
    components: {
      Confirmation_Modal,
      Error_Modal,
      Success_Modal,
      Broken_Page_V01,
    },
    data() {
      return {
        headers: [
          { text: "Account Number:", value: "accountNumber" },
          { text: "SafeBox Name:", value: "fullName" },
          { text: "SafeBox Limit:", value: "creditLimit" },
          { text: "SafeBox Email:", value: "email" },
          { text: "SafeBox Phone Number:", value: "phoneNumber" },
          { text: "Edit/Details", value: "edit" },
          { text: "Delete", value: "delete" },
        ],
        message: null,
        safeBox: [],
        itemsSelected: [],
        searchField: "",
        searchValue: "",
        broken_page: false,
        success: false,
        error: false,
        requiredRule: requiredRule,
        safeBoxName: null,
        AccountNumber: null,
        safeBoxLimit: null,
        safeBoxEmail: null,
        safeBoxPhoneNumber: null,
        confirmation: false,
        confirmed: false,
        QSafeBox: false,
        loading: false,
      };
    },
    methods: {
      formatNumber,
      async getSafeBox() {
        this.loading = true;
        let response = unifiedResponseHandler(
          await third_partyService.getSafeBoxes()
        );
        console.log(response);
        if (response.success) {
          this.loading = false;
          this.safeBox = response.data;
          this.safeBox = response.data.map((item) => ({
            ...item,
            creditLimit: this.formatNumber(item.creditLimit),
          }));
        } else {
          this.broken_page = true;
        }
      },
      NewThirdPartyList() {
        setPage("new_third_party");
      },
      goToEdit(third_party) {
        this.$store.dispatch("setChosenThirdParty", third_party);
        this.$store.dispatch("setIsEditingThirdParty", true);
        setPage("new_third_party");
      },
      getConfirmation() {
        this.confirmed = true;
        this.confirmation = false;
        return true;
      },
      waitForConfirmation() {
        return new Promise((resolve) => {
          const checkVariable = () => {
            if (!this.confirmation) {
              resolve();
            } else {
              setTimeout(checkVariable, 100);
            }
          };
          checkVariable();
        });
      },
      async deleteThirdParty(third_party) {
        this.confirmation = true;
        await this.waitForConfirmation();
        if (this.confirmed) {
          this.confirmed = false;
          let response = unifiedResponseHandler(
            await third_partyService.deleteThirdParty(third_party.id)
          );
          this.message = response.message;
          if (response.success) {
            this.success = true;
            this.getSafeBox();
          } else {
            this.error = true;
          }
        }
      },
    },
    async mounted() {
      await this.getSafeBox();
    },
  };
  </script>
  