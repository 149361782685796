<template>
  <div class="bg-login">
    <v-sheet
        elevation="24"
        width="350"
        height="320"
        class="mx-auto adjust-label"
        style="margin-top: 100px; border-radius: 5px"
    >
      <v-form fast-fail @submit.prevent ref="login_form" class="pa-4">
        <template v-if="!showOTPInput">
          <v-text-field
              v-model="email"
              label="Email"
              :rules="emailRule"
              @input="clearErrorMessage"
              @focus="clearErrorMessage"
          ></v-text-field>

          <v-text-field
              :type="showPassword ? 'text' : 'password'"
              v-model="password"
              label="Password"
              :rules="requiredRule"
              class="password-field"
              @keydown.enter.prevent="moveToOTPVerification"
              @input="clearErrorMessage"
              @focus="clearErrorMessage"
          >
            <v-icon @click="togglePasswordVisibility" class="v-icon" small
            >{{ showPassword ? "mdi-eye" : "mdi-eye-off" }}
            </v-icon>
          </v-text-field>
        </template>
        <template v-else>
          <div
              style="display: flex; align-items: center; justify-content: center"
          >
            <h2 class="font-weight-medium" style="text-align: center; margin-right: 5px">
              Please Check Your Email.
            </h2>
            <v-icon
                class="mdi mdi-spin mdi-refresh"
                v-if="showReloadIcon"
                style="color: var(--main-color)"
            >
              mdi-refresh
            </v-icon>
          </div>
          <div class="otp-container">
            <input
                style="margin-top: 20px"
                v-for="(value, index) in otpValues"
                :key="index"
                v-model="otpValues[index]"
                ref="otpFields"
                :class="{ active: value !== '' }"
                @input="handleInput(index, $event);"
                @keydown.delete="handleBackspace(index)"
                maxlength="1"
            />
          </div>
          <p
              style="
              color: red;
              margin-top: 10px;
              margin-left: 5px;
              font-weight: 500;
            "
          >
            {{ message }}
          </p>
        </template>
        <div
            class="ball-animation"
            v-if="showBallAnimation"
            style="
            width: 20px;
            height: 20px;
            background-color: var(--main-color);
            border-radius: 50%;
            margin-left: 5px;
          "
        ></div>
        <v-btn
            block
            color="button_color"
            type="submit"
            class="mt-8"
            @click="!showOTPInput ? signIn() : verifyOTP()"
        >
          {{ showOTPInput ? "Verify OTP" : "Login" }}
        </v-btn>

        <div
            v-if="showTimer"
            class="timer mt-25px"
            style="color: var(--main-color)"
        >
          Time remaining: {{ formatTime(timer) }}
        </div>
      </v-form>
    </v-sheet>
  </div>
</template>
<script>
import {emailRule} from "@/utils/rules";
import axios from "axios";
import {BASE_URL} from "@/config/endpoints";
import AuthService from "@/services/auth.service";

export default {
  name: "login_v01",
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
      otp: "",
      otpValues: ["", "", "", "", "", ""],
      currentIndex: 0,
      maxOtpLength: 6,
      emailRule: emailRule,
      showReloadIcon: false,
      showOTPInput: false,
      message: null,
      showBallAnimation: false,
      timer: 240,
      intervalId: null,
      showTimer: false,
    };
  },
  computed: {},
  methods: {
    focusOnFirstInput() {
      this.$nextTick(() => {
        this.$refs.otpFields[0].focus();
      });
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(this.intervalId);
          window.location.reload();
        }
      }, 1000);
    },
    formatTime(seconds) {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    clearErrorMessage() {
      this.message = null;
    },
    clearOtpValues() {
      if (this.message === "Incorrect OTP, please try again") {
        this.otpValues = ["", "", "", "", "", ""];
        this.focusOnFirstInput();
      }
    },
    moveToOTPVerification() {
      if (this.email && this.password) {
        this.signIn();
      }
    },
    async signIn() {
      let form = {
        email: this.email,
        password: this.password,
      };
      const {valid} = await this.$refs.login_form.validate();
      if (valid) {
        try {
          this.showBallAnimation = true;
          const response = await axios.post(BASE_URL + "auth/signin", form);
          if (response.status === 200) {
            this.showOTPInput = true;
            this.showTimer = true;
            this.startTimer();
          } else {
            this.message = response.data;
          }
        } catch (error) {
          console.error("Error2:", error);
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        } finally {
          this.showBallAnimation = false;
        }
      }
    },
    async verifyOTP() {
      let form = {
        email: this.email,
        password: this.password,
        otp: this.otpValues.join(""),
      };
      this.showReloadIcon = true;
      console.log(form);
      const {valid} = await this.$refs.login_form.validate();
      if (valid) {
        try {
          await AuthService.verifyOTP(form);
          this.$router.push("/dashboard_v01");
        }
        catch (error) {
          this.message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
      }
    },
    handleInput(index, event) {
      if (this.message === "Incorrect OTP, please try again") {
        this.clearErrorMessage();
      }
      if (event.target.value.length > 1) {
        event.target.value = event.target.value.slice(0, 1);
      }
      this.otpValues[index] = event.target.value;
      if (
          event.target.value.length === 1 &&
          index < this.otpValues.length - 1
      ) {
        this.$refs.otpFields[index + 1].focus();
      }
    },
    handleBackspace(index) {
      if (this.otpValues[index] !== "") {
        this.otpValues[index] = "";
      } else if (index > 0) {
        this.$refs.otpFields[index - 1].focus();
      }
    },
  },
  watch: {
    otpValues: {
      handler: function (newValues) {
        if (newValues.every((value) => value !== "")) {
          this.showReloadIcon = true;
          this.verifyOTP();
        }
      },
      deep: true,
    },
    message: {
      handler: "clearOtpValues"
    }
  },
};
</script>
<style scoped>
.password-field {
  position: relative;
}

.v-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the right distance as needed */
  transform: translateY(-50%);
  cursor: pointer;
}

.otp-container {
  display: flex;
  justify-content: center;
}

input {
  width: 40px;
  height: 40px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
}

input:focus {
  outline: none;
  border-color: var(--main-color);
}

input.active {
  border-color: var(--main-color);
  transform: scale(1.1); /* Increase size slightly when active */
}

/* Add the ball animation class */
/* Add the ball animation class */
.ball-animation {
  animation-name: moveBall;
  animation-duration: 3s; /* Adjust the duration as needed */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; /* Infinite animation loop */
  opacity: 1;
  width: 20px;
  height: 20px;
  background-color: var(--main-color);
  border-radius: 50%;
  position: relative;
  animation-delay: 0.5s; /* Delay before starting the animation */
  /* box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0,0,0, 0.3); Add a subtle shadow */
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0 0 30px rgba(0, 0, 0, 0.3),
  inset 0 0 10px rgba(0, 0, 0, 0.1); /* Inset shadow effect */
}

.bg-login {
  position: absolute;
  background-image: url("../assets/img/green.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

/* Keyframes for ball animation */
@keyframes moveBall {
  0%,
  100% {
    left: 0;
    top: 0;
    transform: translateY(0);
  }
  25% {
    left: 25%;
    transform: translateY(-15px);
  }
  50% {
    left: 50%;
    transform: translateY(15px);
  }
  75% {
    left: 90%;
    transform: translateY(-15px);
  }
}
</style>
