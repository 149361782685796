<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h2>Customer Chart</h2>
      <v-form class="adjust-label" ref="generate_soa_form">
        <v-row>
          <v-col>
            <label for="date-picker" style="color: black">From:</label>
            <VueDatePicker
              auto-apply="true"
              id="date-picker"
              v-model="startDate"
              model-type="yyyy-MM-dd"
              :show-timepicker="false"
            ></VueDatePicker>
          </v-col>
          <v-col>
            <label for="date-picker" style="color: black">To:</label>
            <VueDatePicker
              auto-apply="true"
              id="date-picker"
              v-model="endDate"
              model-type="yyyy-MM-dd"
              :show-timepicker="false"
            ></VueDatePicker>
          </v-col>
          <v-col>
            <v-tab
              @click="thirdPartListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
              >select</v-tab
            >
            <v-text-field
              label="Third Party"
              v-model="thirdPartyFullName"
              @keyup.backspace="clearThirdParty"
              @keydown="handleKeyDown"
              :rules="requiredRule"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <div class="chart-container">

      <bar-chart :labels="chartLabels" :datasets="chartDatasets"/>
      </div>
      <!-- <EasyDataTable
        v-if="thirdPartyType === 'CUSTOMER'"
        :headers="headersCustomer2"
        :items="headersCustomerDetails"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
        body-text-direction="left"
        header-text-direction="left"
        class="custom-data-table"
      >
      </EasyDataTable> -->
    </v-container>

    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>
  </div>
  <v-dialog
    v-if="thirdPartyType == 'CUSTOMER'"
    v-model="thirdPartListModal"
    width="auto"
  >
    <third_party_list_modal
      thirdPartyType="CUSTOMER"
    ></third_party_list_modal>
  </v-dialog>
  <v-dialog v-model="success">
    <div class="success-dialog">
      <div class="success-left">
        <div class="green-circle">
          <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M50 100 L80 130 L150 50"
              stroke="white"
              stroke-width="25"
              fill="transparent"
              class="success-tick"
            />
          </svg>
        </div>
      </div>
      <div class="success-right">
        <span
          class="close-icon"
          @click="success = false"
          style="text-align: right"
          >✖</span
        >
        <h4>Success</h4>
        <p class="success-message">{{ message }}</p>
        <button class="success-close-button" @click="success = false">
          Close
        </button>
      </div>
    </div>
  </v-dialog>

  <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>
      </div>
      <div class="error-right">
        <span
          class="close-icon"
          @click="error = false"
          style="text-align: right"
          >✖</span
        >
        <h4>Error</h4>
        <p class="error-message">{{ message }}</p>
        <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import {formatNumber, unifiedResponseHandler} from "@/utils/helpers";
import Broken_Page_V01 from "../Broken_Page_V01.vue";
import free_cash_reportService from "@/services/free_cash_report.service";
import BarChart from "./Bar_Chart.vue";
import emitter from "@/plugins/mitt";
import third_party_list_modal from "../Modals/Third_Party_List_Modal_V01.vue";

export default {
  name: "currency_exposure",
  components: {
    third_party_list_modal,
    Broken_Page_V01,
    BarChart,
  },
  props: ["thirdPartyType"],
  data() {
    return {
      reportResult: [],
      headersReport: [
        { text: "Exposure", value: "exposure" },
        { text: "Total", value: "value" },
        { text: "AED Equivalent", value: "AEDEquivalent" },
      ],
      currencyTotals: {},
      message: null,
      broken_page: false,
      success: false,
      error: false,
      customerData: [],
      searchField: "",
      searchValue: "",
      aedEquivalentRow: {
        name: "AED Equivalent",
      },
      customerTotalCurrency: [],
      headersCustomer2: [],
      totalForCurrencyExposure: null,
      thirdParty: null,
      thirdPartListModal: false,
      chartLabels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      chartDatasets: [
        {
          data: [40, 20, 12, 50, 0, 4, 18, 29, 45, 0, 21, 78],
          label: "AED Equivalent",
          backgroundColor: "#407BFFaa",
        },
      ],
    };
  },
  methods: {
    assignCurrencyBalance(customer, currencySymbol) {
      const balance =
        parseFloat(
          customer.thirdPartyCurrencyCredit.find(
            (item) => item.currencySymbol === currencySymbol
          )?.balance
        ) || 0;
      this.currencyTotals[currencySymbol] += balance;
      const formattedBalance = formatNumber(balance);
      return formattedBalance;
    },

    async getAEDEquivalent() {
      const currencySymbols = Object.keys(this.currencyTotals);
      let totalAEDEquivalent = 0;
      for (let i = 0; i < currencySymbols.length - 1; i++) {
        const currencySymbol = currencySymbols[i];
        const total = this.currencyTotals[currencySymbol];
        console.log("currencySymbol, total", currencySymbol, total);

        let response = await free_cash_reportService.getAEDEquivalent(
          currencySymbol,
          total
        );

        if (response && response.data) {
          totalAEDEquivalent += response.data.data;
          console.log(`${currencySymbol} equivalent:`, response.data.data);
          this.aedEquivalentRow[currencySymbol] =
            formatNumber(response.data.data) + " " + "AED";
          this.customerTotalCurrency.push({
            currencySymbol,
            equivalent: response.data.data,
          });
          console.log("customerTotalCurrency", this.customerTotalCurrency);
        } else {
          console.error(`Error getting AED equivalent for ${currencySymbol}`);
        }
      }
      this.aedEquivalentRow["totalEmirateBalance"] =
        formatNumber(totalAEDEquivalent) + " " + "AED";
    },
    generateTableDetails(data, headers) {
      const dynamicHeaders = headers.slice(1);
      const formattedData = data.map((item) => {
        const details = {
          name: item.name,
        };
        details.name = item.thirdPartyName;

        for (const currency of dynamicHeaders) {
          details[currency.value] = this.assignCurrencyBalance(
            item,
            currency.value
          );
        }

        details.totalEmirateBalance =
          formatNumber(item.totalEmirateBalance) + " AED";
        console.log("item", item);
        console.log("details", details);
        return details;
      });

      const emptyRow = {
        name: "",
        totalEmirateBalance: "",
      };

      const currencyTotalsRow = {
        name: "Currency Totals",
      };

      for (const currency of dynamicHeaders) {
        currencyTotalsRow[currency.value] =
          formatNumber(this.currencyTotals[currency.value]) +
          " " +
          currency.value;
      }

      currencyTotalsRow.totalEmirateBalance = "";
      formattedData.push(emptyRow);
      formattedData.push(currencyTotalsRow);
      formattedData.push(this.aedEquivalentRow);

      return formattedData;
    },
    async getHeader() {
      let response = unifiedResponseHandler(
        await free_cash_reportService.getCustomerHeader()
      );
      if (response.success) {
        response.data.forEach((symbol) => {
          this.currencyTotals[symbol] = 0;
        });
        response.data.forEach((symbol) => {
          this.aedEquivalentRow[symbol] = "";
        });
        const dynamicHeaders = response.data.map((symbol) => {
          return { text: symbol, value: symbol };
        });
        const staticItem = { text: "Customer", value: "name" };
        const staticItem2 = {
          text: "AED Equivalent",
          value: "totalEmirateBalance",
        };
        this.headersCustomer2 = [staticItem, ...dynamicHeaders, staticItem2];
        console.log("this.headersCustomer2", this.headersCustomer2);
        console.log("currencyTotals:", this.currencyTotals);
        console.log("aedEquivalentRow:", this.aedEquivalentRow);
      } else {
        this.broken_page = true;
      }
    },
    async getReport() {
      console.log("thirdPartyType", this.thirdPartyType);
      let response;
      response = unifiedResponseHandler(
        await free_cash_reportService.getFreeCashReportCustomer()
      );
      console.log("CUSTOMER", response);
      if (response.success) {
        this.customerData = response.data;
      } else {
        this.broken_page = true;
      }
    },
  },
  async mounted() {
    await this.getHeader();
    await this.getReport();
    await this.getAEDEquivalent();
    emitter.on("new_third_party", (value) => {
      this.thirdParty = value;
      this.thirdPartListModal = false;
    });
  },
  computed: {
    headersCustomerDetails() {
      return this.generateTableDetails(
        this.customerData,
        this.headersCustomer2
      );
    },
    thirdPartyFullName() {
      if (this.thirdParty) {
        return this.thirdParty.fullName;
      } else {
        return null;
      }
    },

    thirdPartyId() {
      if (this.thirdParty) {
        return this.thirdParty.id;
      } else {
        return null;
      }
    },
  },
};
</script>
<style>
.chart-container {
  max-width: 1200px; /* Set your desired max-width */
  margin: 0 auto;   /* Center the chart horizontally, optional */
}
</style>
