<template>
  <v-container fluid>
    <v-form
        ref="new_user_permission_form"
        class="mt-4"
    >
      <v-row>
        <v-col cols="12" md="6" sm="12" xs="12">
          <v-tab
              @click="userListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
          >select
          </v-tab>
          <v-text-field
              v-model="selectedUserFirstName"
              label="Selected User First Name"
              :rules="requiredRule()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col class="mt-0 mb-0" cols="auto">
          <v-btn class="mr-4" color="button_color"
                 @click="permitUserAccessToGroup">
            Permit User Access To Group
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <template v-if="users && users.length > 0">
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="users">
              <v-icon>mdi-export</v-icon>
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <v-card variant="outlined">
        <v-card-title class="text-h5 py-3 text-center">
          Allowed Users
        </v-card-title>
        <v-table height="300px">
          <thead>
          <tr>
            <th class="text-left" v-for="header in headers" :key="header">{{ header['text'] }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="user in users" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.lastName }}</td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.cellPhoneNumber }}</td>
            <td>{{ user.role }}</td>
            <td>
              <v-icon @click="removeUserAccessFromGroup(user)">mdi-delete</v-icon>
            </td>
            <td>
              <v-icon @click="goToEdit(user)">mdi-pencil</v-icon>
            </td>
          </tr>
          </tbody>
        </v-table>
      </v-card>
    </template>

    <Success_Modal v-if="response || requestResult === 1"
                   :message="message"
                   :success="requestResult === 1"
                   @click="closeModal"
    ></Success_Modal>

    <Error_Modal v-if="response || requestResult === -1"
                 :error="requestResult === -1"
                 :message="message"
                 @closeErrorModal="closeModal"
    ></Error_Modal>
    <Confirmation_Modal
        :confirmation="confirmation"
        @DoConfirmation="getConfirmation()"
        @closeConfirmationModal="confirmation = false"
    ></Confirmation_Modal>
  </v-container>

  <v-dialog
      v-model="userListModal"
      width="auto"
  >
    <Users_list_modal/>
  </v-dialog>

</template>

<script>
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";
import {unifiedResponseHandler, setPage} from "@/utils/helpers.js";
import third_party_groupService from "@/services/third_party_group.service.js";
import userService from "@/services/user.service.js";
import Users_list_modal from "@/components/Modals/User_List_Modal_V01.vue";
import emitter from "@/plugins/mitt.js";
import {requiredRule} from "@/utils/rules.js";

export default {
  components: {Users_list_modal, Confirmation_Modal, Error_Modal, Success_Modal},
  props: ['thirdPartyGroup'],

  data() {

    return {
      loading: true,
      response: null,
      message: '',
      users: [],
      requestResult: 0,
      headers: [
        {text: 'ID', value: 'ID'},
        {text: 'Last name', value: 'lastName'},
        {text: 'First name', value: 'firstName'},
        {text: 'Email', value: 'email'},
        {text: 'Cell phone', value: 'cellPhoneNumber'},
        {text: 'Role', value: 'role'},
        {text: 'Delete', value: 'delete'},
        {text: 'Edit', value: 'edit'},
      ],
      confirmation: false,
      confirmed: false,
      chosenUser: null,
      enteredUserId: "",
      userListModal: false,
    }
  },

  methods: {
    requiredRule() {
      return requiredRule
    },
    async getUsers() {
      this.loading = true;
      let response = unifiedResponseHandler(await third_party_groupService.getPermittedUsers(this.thirdPartyGroup.id));
      if (response.success) {
        this.loading = false;
        this.users = response.data
        console.log(this.users)
      }
    },

    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async removeUserAccessFromGroup(user) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
            await userService.removeUserAccessToThirdPartyGroup(user.id, this.thirdPartyGroup.id)
        );
        this.message = response.message;
        if (response.success) {
          this.requestResult = 1;
          await this.getUsers();
        } else {
          this.requestResult = -1;
        }
      }
    },
    async permitUserAccessToGroup() {
      if (this.enteredUserId === "" || this.enteredUserId === null) {
        this.message = "Please enter user id";
        this.requestResult = -1
        return;
      }
      if (await this.$refs.new_user_permission_form.validate()) {
        let response = unifiedResponseHandler(await userService.permitUserAccessToThirdPartyGroup(this.enteredUserId, this.thirdPartyGroup.id))
        this.message = response.message
        if (response.success) {
          this.loading = false;
          this.requestResult = 1;
          await this.getUsers()
        } else {
          this.requestResult = -1;
        }
        this.chosenUser = "";
      }
    },

    goToEdit(user) {
      this.$store.dispatch("setChosenUser", user);
      this.$store.dispatch("setIsEditingUser", true);
      setPage("new_user");
    },

    closeModal() {
      this.requestResult = 0;
      this.response = null;
    }
  },

  async mounted() {
    emitter.on("new_user", (value) => {
      if (this.userListModal) {
        this.chosenUser = value;
        this.enteredUserId = this.chosenUser.id
        this.userListModal = false;
      }
    });
    await this.getUsers();
  },
  computed: {
    selectedUserFirstName() {
      return this.chosenUser?.firstName || null
    }
  }
}
</script>


<style scoped>

.styled-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

.styled-table tr:hover {
  background-color: #f1f1f1;
}
</style>