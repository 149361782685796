<template>
  <div>
    <v-container v-if="!broken_page" fluid>
      <h3 class="font-weight-medium">Payers/Payees</h3>
      <v-row>
        <v-col>
          <v-text-field
              class="adjust-label search-field"
              label="Search"
              v-model="searchValue"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex justify-end">
          <v-btn
              color="button_color"
              @click="NewThirdPartyList()"
              icon="mdi-plus"
              class="ml-2"
          >
          </v-btn>
          <v-btn
              color="button_color"
              @click="QSecondaryThirdParty = true"
              icon="mdi-help"
              class="ml-2"
          >
          </v-btn>
          <v-btn color="button_color" icon="mdi-export" class="ml-2">
            <download-excel :data="thirdParties"
            >
              <v-icon>mdi-export</v-icon>
            </download-excel
            >
          </v-btn>
        </v-col>
      </v-row>

      <EasyDataTable
          :headers="headers"
          :items="thirdParties"
          :search-field="searchField"
          :search-value="searchValue"
          body-text-direction="center"
          header-text-direction="center"
          alternating
          border-cell
          class="custom-data-table"
          :loading="loading"
      >
        <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteThirdParty(item)">mdi-delete</v-icon>
        </template>
        <template #item-userDetails="item">
          <v-icon @click="showThirdPartyDetails(item)">mdi-chevron-down</v-icon>
        </template>
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>

    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
    <Confirmation_Modal
        :confirmation="confirmation"
        @DoConfirmation="getConfirmation()"
        @closeConfirmationModal="confirmation = false"
    ></Confirmation_Modal>
    <v-dialog v-model="QSecondaryThirdParty" width="auto">
      <Q_SecondaryThirdParty_Modal_V01></Q_SecondaryThirdParty_Modal_V01>
    </v-dialog>
    <v-dialog v-model="showDetails" width="720">
      <v-card style="padding: 20px">
        <div class="border-1">
          <div ref="pdfContent" style="text-align: left; direction: ltr">
            <div class="grid-card-2">
              <h6>Full Name</h6>
              <p class="m-l-35">{{ payeerPayeeDetails.fullName }}</p>
              <h6>Account Number</h6>
              <p class="m-l-35">{{ payeerPayeeDetails.accountNumber }}</p>
            </div>
            <div class="grid-card-3">
              <div
                  style="
                  border-top: 1px solid #cac9c9;
                  padding-top: 20px;
                  padding-right: 20px;
                "
                  v-for="(item, index) in payeerPayeeDetailsBankInfo.data"
                  :key="index"
              >
                <h6>Bank Acc {{ index + 1 }}</h6>
                <h7>Acc Number</h7>
                <p>
                  {{ item.accountNumber ? item.accountNumber : "-" }}
                </p>
                <h7>Bank Branch</h7>
                <p>{{ item.bankBranch ? item.bankBranch : "-" }}</p>
                <h7>Bank Name</h7>
                <p>{{ item.bankName ? item.bankName : "-" }}</p>
                <h7>Currency</h7>
                <p>{{ item.currency ? item.currency : "-" }}</p>
                <h7>IBAN Number</h7>
                <p>{{ item.ibannumber ? item.ibannumber : "-" }}</p>
                <h7>Swift Code</h7>
                <p>{{ item.swiftCode ? item.swiftCode : "-" }}</p>
              </div>
            </div>
          </div>
          <v-row justify="end">
            <v-btn class="mr-4" color="button_color" @click="generatePDF"
            >PDF
            </v-btn
            >
            <v-btn
                class="mr-4"
                color="button_color"
                @click="showDetails = false"
            >Close
            </v-btn
            >
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Q_SecondaryThirdParty_Modal_V01 from "@/components/ModalQ/Q_SecondaryThirdParty_Modal_V01.vue";
import third_partyService from "@/services/third_party.service";
import {
  setPage,
  unifiedResponseHandler,
} from "@/utils/helpers";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import html2pdf from "html2pdf.js";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";

export default {
  name: "third_party_list",
  components: {
    Confirmation_Modal,
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Q_SecondaryThirdParty_Modal_V01,
  },
  data() {
    return {
      headers: [
        {text: "Account Number", value: "accountNumber"},
        {text: "Full Name", value: "fullName"},
        {text: "Payers/Payees Email:", value: "email"},
        {text: "Payers/Payees Phone Number:", value: "phoneNumber"},
        {text: "Edit/Details", value: "edit"},
        {text: "Delete", value: "delete"},
      ],
      searchField: "",
      searchValue: "",
      itemsSelected: [],
      message: null,
      broken_page: false,
      success: false,
      error: false,
      thirdParties: [],
      payeerPayeeDetails: [],
      payeerPayeeDetailsBankInfo: [],
      confirmation: false,
      confirmed: false,
      QSecondaryThirdParty: false,
      showDetails: false,
      loading: false,
    };
  },

  methods: {
    generatePDF() {
      const element = this.$refs.pdfContent;
      const options = {
        margin: 10,
        filename: "payeerPayeeDetails.pdf",
        image: {type: "jpeg", quality: 0.98},
        html2canvas: {scale: 2},
        jsPDF: {unit: "mm", format: "a4", orientation: "portrait"},
      };

      html2pdf(element, options);
    },
    goToEdit(third_party) {
      this.$store.dispatch("setChosenThirdParty", third_party);
      this.$store.dispatch("setIsEditingThirdParty", true);
      setPage("new_third_party");
    },
    NewThirdPartyList() {
      this.$store.dispatch("setChosenThirdParty", null);
      this.$store.dispatch("setIsEditingThirdParty", false);
      setPage("new_third_party");
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    async getThirdParties() {
      this.loading = true;
      let response = unifiedResponseHandler(
          await third_partyService.getSecondaryThirdParties()
      );
      if (response.success) {
        this.loading = false;
        this.thirdParties = response.data;
      } else {
        this.broken_page = true;
      }
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async deleteThirdParty(third_party) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
            await third_partyService.deleteThirdParty(third_party.id)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.getThirdParties();
        } else {
          this.error = true;
        }
      }
    },
  },
  async mounted() {
    await this.getThirdParties();
  },
};
</script>
