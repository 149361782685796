<template>

    <div style="
      display: flex;
      align-items: center; 
      flex-direction: column; 
      margin-top: 2rem;"
      >

     <h1 class="font-weight-medium mb-1">Welcome to Ellington</h1>
     <p>Your gateway to financial wisdom and wealth-building strategies. </p>
      <v-img
          :width="600"
          aspect-ratio="1"
          cover
          :src="getImageUrl('home3-g', 'svg')"
      ></v-img>
    </div>

</template>
<script>
import { setPage } from "@/utils/helpers";
import {getImageUrl} from "@/utils/NewFunctions/utils.js";
export default {
  name: "home_main_v01",
  methods: {
    getImageUrl,
    setPage(page) {
      setPage(page);
    },
  },
};
</script>
