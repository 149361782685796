<template>
    <v-container v-if="!broken_page" fluid>
    <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            class="adjust-label"
            label="Search"
            v-model="searchValue"
          ></v-text-field>
        </v-col>
      </v-row>
       <EasyDataTable
        :headers="headers"
        :items="countries"
        item-title="name"
        :search-field="searchField"
        :search-value="searchValue"
        alternating
        border-cell
        class="custom-data-table"
      >
     
      </EasyDataTable>
    </v-container>
    <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>

  <v-dialog v-model="success">
    <div class="success-dialog">
      <div class="success-left">
        <div class="green-circle">
          <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
            <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
      </svg>
    </div>      
      </div>
      <div class="success-right">
      <span class="close-icon" @click="success = false" style="text-align: right;">✖</span>
      <h4>Success</h4>
    <p class="success-message">Everything is good!</p>
    <button class="success-close-button" @click="success = false">Close</button>
      </div>
    </div>
</v-dialog>
  <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span class="close-icon" @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">Sorry, an error occurred. Please try again later.</p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>
    <v-dialog v-model="currencyListModal" width="auto">
      <Currency_List_Modal_V01></Currency_List_Modal_V01>
    </v-dialog>
</template>

<script>

import CountryService from "@/services/country.service";
import {requiredRule} from "@/utils/rules.js";
import emitter from "@/plugins/mitt";
import Currency_List_Modal_V01 from "../../Modals/Currency_List_Modal_V01.vue";
import {
  unifiedResponseHandler,
 } from "@/utils/helpers";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";

export default{
    name: "country_database_v01",
    components:{
        Currency_List_Modal_V01,
        Broken_Page_V01,

    },
    data() {
    return {
        Id: null,
      step: 'list',
      headers: [
        { text: "Country", value: "name" },
        { text: "Code", value: "code" },
        { text: "Code_iso", value: "code_iso" },
        // { text: "Active", value: "active" },
      ],
      countries: [],
      itemsSelected: [],
      searchField: "",
      searchValue: "",
      broken_page: false,
      success: false,
      error: false,
      warning:false,
      // info:true,
      message: null,
      requiredRule: requiredRule,
      countryName: null,
      countryCode: null,
      countryCodeIso: null,
      countryCurrency: null,
      currencyListModal: false,

    };
  },
  methods:{
    async getCountries() {
      let response = unifiedResponseHandler(
        await CountryService.getCountries());
        console.log(response);

      if (response.success) {
        this.countries = response.data;
      }else {
        this.broken_page = true;
      }
    },
    async cancel() {
      await this.$refs.form.reset();
    },
  },
  computed:{
    currencyId(){
        if (this.countryCurrency) {
        return this.countryCurrency.id;
      } else {
        return null;
      }
    }
  },
  async mounted() {   
    await this.getCountries(); 
    emitter.on("currency_database_id", (value) => {
        this.countryCurrency = value.name;
        this.currencyListModal = false;
    });
  }
}
</script>

