<template>
  <div style="
      display: flex;
      align-items: center; 
      flex-direction: column; 
      margin-top: 2rem;">

    <h1 class="font-weight-medium">{{ $t("commerce") }}</h1>
    <v-img
        :width="700"
        aspect-ratio="1"
        cover
        :src="getImageUrl('commerce-g', 'svg')"
    ></v-img>
  </div>
</template>
<script>
import {getImageUrl} from "@/utils/NewFunctions/utils.js";

export default {
  name: "money_transfer_home_page_v01",
  methods: {getImageUrl},
  data() {
  }
};
</script>
  