<template>
  <v-container fluid>
    <h3 class="mb-2 font-weight-medium" >{{ internalTransferTypeFormat(internalTransferType) }}</h3>
    <v-form class="adjust-label" ref="internal_transfer_form">
      <v-row class="mt-4">
        <v-col cols="12" md="3" sm="12" xs="12">
          <v-tab
              prepend-icon="mdi-magnify"
              color="button_color"
              @click="sourceAccountListModal = true"
          >select
          </v-tab>
          <v-text-field
              @keyup.backspace="clearSourceAccount"
              @keydown="handleKeyDown"
              v-model="sourceAccountCofferName"
              label="Source Account"
          ></v-text-field>
          <v-text-field
              readonly
              label="Source Actual Amount"
              v-model="sourceAccountBalance"
              variant="outlined"
              :disabled="!sourceAccount"
              class="mt-25px"
          ></v-text-field>
          <v-text-field
              v-maska:[moneyOption]
              v-model="amount"
              label="Source Amount"
              @keyup.backspace="clearAmountTargetRate"
              :variant="(amountReadOnly === true) ? 'outlined' : 'filled'"
              :readonly="amountReadOnly"
          >
          </v-text-field>

          <v-text-field
              v-maska:[percentOption]
              label="Spread%"
              v-model="spreadValue"
          ></v-text-field>
          <v-text-field
              v-maska:[moneyOption]
              label="Source Com Amt"
              v-model="withdrawalCommissionAmount"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12">
          <v-tab
              prepend-icon="mdi-magnify"
              color="button_color"
              @click="targetAccountListModal = true"
          >select
          </v-tab>
          <v-text-field
              @keyup.backspace="clearTargetAccount"
              @keydown="handleKeyDown"
              v-model="targetAccountCofferName"
              label="Target Account"
          ></v-text-field>
          <v-text-field
              readonly
              label="Target Actual Amount"
              v-model="targetAccountBalance"
              variant="outlined"
              :disabled="!targetAccount"
              class="mt-25px"
          >
          </v-text-field>

          <v-text-field
              label="Target Amount:"
              v-model="targetAmount"
              v-maska:[moneyOption2]
              @keyup.backspace="clearAmountTargetRate"
              :variant="targetReadOnly === true ? 'outlined' : 'filled'"
              :readonly="targetReadOnly"
          >
          </v-text-field>

          <v-text-field
              :disabled="
                !(
                  spreadValue &&
                  (sourceToTargetMustBeSeenExRate || targetToSourceMustBeSeenExRate) &&
                  isLoading &&
                  fromCurrency &&
                  toCurrency &&
                  exchangeRate
                )
              "
              readonly
              label="Bid:"
              v-model="formatNumberInternalBidAmount"
          ></v-text-field>

          <v-text-field
              v-maska:[percentOption]
              label="Source Com %"
              v-model="withdrawalCommissionPercent"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12">
          <v-text-field
              class="mt-25px"
              label="Reference No."
              v-model="referenceNo"
          ></v-text-field>
          <v-text-field
              v-if="internalTransferType === 'CUSTOMER_TO_CUSTOMER'"
              style="visibility: hidden"
              class="mt-25px"
          >
          </v-text-field>
          <v-tab
              v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
              @click="secondaryThirdPartyAccountListModal = true"
              prepend-icon="mdi-magnify"
              color="button_color"
          >select
          </v-tab>

          <v-text-field
              v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
              label="Payer/Payee"
              v-model="secondaryThirdPartyAccountCofferName"
              @keyup.backspace="clearSecondaryThirdPartyAccount"
              @keydown="handleKeyDown"
          ></v-text-field>
          <v-text-field
              v-maska:[exchangeRateOption]
              :label="$t('user exchange rate')"
              v-model="exchangeRate"
              @keyup.backspace="clearAmountTargetRate"
              :variant="(rateReadOnly === true ? 'outlined' : 'filled')"
              :readonly="rateReadOnly"
          >
            <template v-slot:append>
              <v-btn
                  color="button_color"
                  @click="calculateTargetAmountOrRate"
                  icon="mdi-check"
              ></v-btn>
            </template>
          </v-text-field>
          <v-text-field
              :disabled="
                !(
                  spreadValue &&
                  (sourceToTargetMustBeSeenExRate || targetToSourceMustBeSeenExRate) &&
                  isLoading &&
                  fromCurrency &&
                  toCurrency
                )
              "
              readonly
              label="Ask:"
              v-model="formatNumberInternalAskAmount"
          ></v-text-field>
          <v-text-field
              v-maska:[moneyOption2]
              label="Target Com Amt"
              v-model="depositCommissionAmount"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="12" xs="12">
          <div class="mt-5"><label for="date-picker" style="color: black; margin-top: 20px">
            Date
          </label>
            <VueDatePicker
                auto-apply="true"
                id="date-picker"
                v-model="date"
                :show-timepicker="false"
            ></VueDatePicker>
          </div>
          <v-text-field style="visibility: hidden" class="mt-25px">
          </v-text-field>
          <v-text-field
              style="margin-top: 21px;"
              v-maska:[exchangeRateOption]
              label="Transaction Time XE Rate"
              v-model="transactionTimeXERate"
          >
          </v-text-field>
          <v-text-field
              :disabled="!(isLoading && fromCurrency && toCurrency)"
              readonly
              label="Live XE Rate"
              v-model="formatNumberInternalExchangeRate"
          >
            <template v-slot:append-inner>
              <v-btn
                  color="button_color"
                  @click="getExchangeRate"
                  icon="mdi-update"
              >
              </v-btn>
            </template>
          </v-text-field>
          <v-text-field
              v-maska:[percentOption]
              label="Target Com %"
              v-model="depositCommissionPercent"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12" md="12">
          <v-text-field v-model="description" label="Note"></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="end">
        <div class="d-flex top-20">
          <v-btn class="ml-4" color="button_color" @click="showReport = true">
            Add Transaction
          </v-btn>
          <v-btn class="ml-4" color="button_color" @click="clearFormFields">
            Cancel
          </v-btn>
        </div>
      </v-row>
    </v-form>

    <Success_Modal
        :message="message"
        :success="success"
        @closeSuccessModal="success = false"
    ></Success_Modal>
    <Error_Modal
        :message="message"
        :error="error"
        @closeErrorModal="error = false"
    ></Error_Modal>
    <v-dialog v-model="secondaryThirdPartyAccountListModal" width="auto">
      <list_of_accounts_modal_v01
          dir="secondaryThirdPartyAccount"
          third-party-type="SECONDARY_THIRDPARTY"
      >
      </list_of_accounts_modal_v01>
    </v-dialog>
    <v-dialog v-model="sourceAccountListModal" width="auto">
      <list_of_accounts_modal_v01
          dir="source"
          :third-party-type="
            this.internalTransferType === 'TRUSTEE_TO_TRUSTEE'
              ? 'TRUSTEE'
              : 'CUSTOMER'
          "
      ></list_of_accounts_modal_v01>
    </v-dialog>

    <v-dialog v-model="targetAccountListModal" width="auto">
      <list_of_accounts_modal_v01
          dir="target"
          :third-party-type="
            this.internalTransferType === 'TRUSTEE_TO_TRUSTEE'
              ? 'TRUSTEE'
              : 'CUSTOMER'
          "
      ></list_of_accounts_modal_v01>
    </v-dialog>

    <v-dialog v-model="showReport" max-width="720">
      <v-card style="padding: 20px">
        <div class="border-1">
          <h5 style="text-align: center">
            {{ internalTransferTypeFormat(internalTransferType) }}
          </h5>
          <div class="grid-card-2">
            <p>Date:</p>
            <p class="m-l-35">{{ date }}</p>
            <p>Source Account:</p>
            <p class="m-l-35">{{ sourceAccountCofferName }}</p>
            <p>Source Amount:</p>
            <p class="m-l-35">{{ amount }}</p>
            <p>Target Account:</p>
            <p class="m-l-35">{{ targetAccountCofferName }}</p>
            <p>Target Amount:</p>
            <p class="m-l-35">
              {{ targetAmount }}
            </p>
            <p v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'">
              Payer/Payee:
            </p>
            <p
                v-if="internalTransferType === 'TRUSTEE_TO_TRUSTEE'"
                class="m-l-35"
            >
              {{ secondaryThirdPartyAccountCofferName }}
            </p>
            <p>Spread%:</p>
            <p class="m-l-35">{{ spreadValue }}</p>
            <p>Ask Amount:</p>
            <p class="m-l-35">{{ formatNumberInternal(askAmount) }}</p>
            <p>Bid Amount:</p>
            <p class="m-l-35">{{ formatNumberInternal(bidAmount) }}</p>
            <p>{{ $t("exchange rate") }}:</p>
            <p class="m-l-35">{{ exchangeRate }}</p>
            <p>Reference No:</p>
            <p class="m-l-35">{{ referenceNo }}</p>
            <p>Source Com Amt:</p>
            <p class="m-l-35">{{ withdrawalCommissionAmount }}</p>
            <p>Source Com %:</p>
            <p class="m-l-35">{{ withdrawalCommissionPercent }}</p>
            <p>Target Com Amt:</p>
            <p class="m-l-35">{{ depositCommissionAmount }}</p>
            <p>Target Com %:</p>
            <p class="m-l-35">{{ depositCommissionPercent }}</p>
            <p>Note:</p>
            <p class="m-l-35">{{ description }}</p>
          </div>
          <v-row justify="end">
            <div class="d-flex top-20">
              <v-btn
                  color="button_color"
                  style="margin-right: 10px"
                  @click="addRequest()"
              >{{ $t("confirm") }}
              </v-btn>
              <v-btn color="button_color" @click="showReport = false"
              >Cancel
              </v-btn>
            </div>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  extractNumber,
  formatNumber,
  formatNumberInternal,
  handleKeyDown,
  unifiedResponseHandler,
  setPage,
} from "@/utils/helpers";

import {ExchangeRateOption, MoneyOption, PercentageOption,} from "@/utils/maska_options";
import {
  multipleFileRequiredRule,
  numericFiveDigitRequiredRule,
  numericRequiredRule,
  numericRule,
  requiredRule,
} from "@/utils/rules";
import list_of_accounts_modal_v01 from "../Modals/List_Of_Accounts_Modal_V01.vue";
import emitter from "@/plugins/mitt";
import {mapGetters} from "vuex";
import exchange_rateService from "@/services/exchange_rate.service";
import internal_transferService from "@/services/internal_transfer.service";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";

export default {
  name: "p_internal_transfer_v01",
  props: ["internalTransferType"],
  components: {
    Error_Modal,
    Success_Modal,
    list_of_accounts_modal_v01,
  },

  data() {
    return {
      isLoading: false,
      exchangeRateOption: ExchangeRateOption,
      percentOption: PercentageOption,
      error: false,
      success: false,
      message: null,
      requiredRule: requiredRule,
      numericRule: numericRule,
      numericFiveDigitRequiredRule: numericFiveDigitRequiredRule,
      numericRequiredRule: numericRequiredRule,
      requestId: null,
      amount: null,
      multipleFileRequiredRule: multipleFileRequiredRule,
      sourceAccountListModal: false,
      targetAccountListModal: false,
      sourceAccount: null,
      targetAccount: null,
      exchangeRate: null,
      transactionTimeXERate: null,
      formatNumberInternalExchangeRate: null,
      description: null,
      withdrawalCommissionAmount: null,
      withdrawalCommissionPercent: null,
      depositCommissionPercent: null,
      depositCommissionAmount: null,
      sourceToTargetMustBeSeenExRate: null,
      targetToSourceMustBeSeenExRate: null,
      fromCurrency: null,
      toCurrency: null,
      referenceNo: null,
      spreadValue: null,
      XESourceToTargetRate: null,
      XETargetToSourceRate: null,
      showReport: false,
      date: null,
      loadingT: false,
      secondaryThirdPartyAccountListModal: false,
      secondaryThirdPartyAccount: null,
      targetAmount: null,
      targetReadOnly: false,
      amountReadOnly: false,
      rateReadOnly: false,
    };
  },
  methods: {
    formatNumber,
    internalTransferTypeFormat(internalTransferType) {
      let transactionTypeDisplay = "";
      if (internalTransferType === "TRUSTEE_TO_TRUSTEE") {
        transactionTypeDisplay = "Past Internal Trustee";
      } else if (internalTransferType === "CUSTOMER_TO_CUSTOMER") {
        transactionTypeDisplay = "Past Internal Customer";
      }
      return transactionTypeDisplay;
    },
    clearFormFields() {
      this.sourceAccountCofferName = null;
      this.amount = null;
      this.spreadValue = null;
      this.exchangeRate = null;
      this.targetAccountCofferName = null;
      this.targetAmount = null;
      this.bidAmount = null;
      this.askAmount = null;
      this.referenceNo = null;
      this.withdrawalCommissionAmount = null;
      this.withdrawalCommissionPercent = null;
      this.depositCommissionAmount = null;
      this.depositCommissionPercent = null;
      this.description = null;
      this.sourceToTargetMustBeSeenExRate = null;
      this.targetToSourceMustBeSeenExRate = null;
      this.fromCurrency = null;
      this.toCurrency = null;
      this.sourceAccount = null;
      this.targetAccount = null;
      this.isLoading = false;
      this.date = null;
      this.secondaryThirdPartyAccount = null;
      this.formatNumberInternalExchangeRate = null;
      this.targetReadOnly = false;
      this.amountReadOnly = false;
      this.rateReadOnly = false;
    },
    formatNumberInternal,
    exit() {
      setPage("internal report");
    },
    clearSecondaryThirdPartyAccount() {
      this.secondaryThirdPartyAccount = null;
    },
    clearAmountTargetRate() {
      this.amount = null;
      this.targetAmount = null;
      this.exchangeRate = null;
      this.targetReadOnly = false;
      this.amountReadOnly = false;
      this.rateReadOnly = false;
    },
    async getExchangeRate() {
      if (this.sourceAccount && this.targetAccount) {
        let sourceToTargetResponse = unifiedResponseHandler(
            await exchange_rateService.callXE(
                this.sourceAccount.currency.symbol,
                this.targetAccount.currency.symbol
            )
        );
        let targetToSourceResponse = unifiedResponseHandler(
            await exchange_rateService.callXE(
                this.targetAccount.currency.symbol,
                this.sourceAccount.currency.symbol
            )
        );
        if (sourceToTargetResponse.success && targetToSourceResponse.success) {
          this.XESourceToTargetRate = sourceToTargetResponse.data;
          this.XETargetToSourceRate = targetToSourceResponse.data;
          this.isLoading = true;
          if (this.XESourceToTargetRate >= this.XETargetToSourceRate) {
            this.sourceToTargetMustBeSeenExRate = sourceToTargetResponse.data;
            this.formatNumberInternalExchangeRate = this.fromCurrency.symbol + " to " + this.toCurrency.symbol + " : " +
                formatNumberInternal(sourceToTargetResponse.data);
            const spreadPercentage = extractNumber(this.spreadValue) / 100;
            this.bidAmount = extractNumber(this.sourceToTargetMustBeSeenExRate) * (1 - spreadPercentage);
            this.askAmount = extractNumber(this.sourceToTargetMustBeSeenExRate) * (1 + spreadPercentage);
            if (this.askAmount > this.bidAmount) {
              this.tickBid = true;
              this.tickAsk = false;
            } else {
              this.tickBid = false;
              this.tickAsk = true;
            }
          } else {
            this.targetToSourceMustBeSeenExRate = targetToSourceResponse.data;
            this.formatNumberInternalExchangeRate =
                this.targetAccount.currency.symbol +
                " to " +
                this.sourceAccount.currency.symbol +
                " : " +
                formatNumberInternal(targetToSourceResponse.data);
            const spreadPercentage = extractNumber(this.spreadValue) / 100;
            this.bidAmount =
                extractNumber(this.targetToSourceMustBeSeenExRate) * (1 - spreadPercentage);
            this.askAmount =
                extractNumber(this.targetToSourceMustBeSeenExRate) * (1 + spreadPercentage);
            if (this.askAmount > this.bidAmount) {
              this.tickBid = true;
              this.tickAsk = false;
            } else {
              this.tickBid = false;
              this.tickAsk = true;
            }
          }
        } else {
          if (!this.sourceToTargetMustBeSeenExRate) {
            this.sourceToTargetMustBeSeenExRate = "NOT FOUND";
          } else {
            this.targetToSourceMustBeSeenExRate = "NOT FOUND";
          }
        }
      }
    },
    calculateTargetAmountOrRate() {
      if (this.exchangeRate && this.amount && this.XESourceToTargetRate && (this.targetAmount === "" || this.targetReadOnly || this.targetAmount === null)) {
        if (this.XESourceToTargetRate > 1) { // Source currency is stronger
          this.targetAmount = extractNumber(this.amount) * extractNumber(this.exchangeRate);
          this.targetAmount = formatNumberInternal(this.targetAmount) + " " + this.toCurrency.symbol;
        } else {
          this.targetAmount = extractNumber(this.amount) / extractNumber(this.exchangeRate);
          this.targetAmount = formatNumberInternal(this.targetAmount) + " " + this.toCurrency.symbol;
        }
        this.targetReadOnly = true;
      } else if (this.targetAmount && this.amount && (this.exchangeRate === "" || this.rateReadOnly || this.exchangeRate === null)) {
        this.exchangeRate = (parseInt(extractNumber(this.targetAmount)) > parseInt(extractNumber(this.amount))) ?
            extractNumber(this.targetAmount) / extractNumber(this.amount) :
            extractNumber(this.amount) / extractNumber(this.targetAmount);
        this.rateReadOnly = true;
      } else if (this.targetAmount && this.exchangeRate && this.XESourceToTargetRate && (this.amount === "" || this.amountReadOnly || this.amount === null)) {
        if (this.XESourceToTargetRate > 1) {
          this.amount = extractNumber(this.targetAmount) / extractNumber(this.exchangeRate);
          this.amount = formatNumberInternal(this.amount) + " " + this.fromCurrency.symbol;
        } else {
          this.amount = extractNumber(this.targetAmount) * extractNumber(this.exchangeRate);
          this.amount = formatNumberInternal(this.amount) + " " + this.fromCurrency.symbol;
        }
        this.amountReadOnly = true;
      }
    },

    async addRequest() {
      let {valid} = await this.$refs.internal_transfer_form.validate();
      if (valid) {
        let form = {
          internalTransferType: this.internalTransferType,
          sourceAccountId: this.sourceAccountId,
          targetAccountId: this.targetAccountId,
          amount: extractNumber(this.amount),
          targetAmount: extractNumber(this.targetAmount),
          exchangeRate: extractNumber(this.exchangeRate),
          transactionTimeXERate: this.transactionTimeXERate,
          description: this.description,
          referenceNo: this.referenceNo,
          secondaryThirdPartyAccountId: this.secondaryThirdPartyAccountId,
          withdrawalCommissionAmount: extractNumber(this.withdrawalCommissionAmount),
          withdrawalCommissionPercent: extractNumber(this.withdrawalCommissionPercent),
          depositCommissionPercent: extractNumber(this.depositCommissionPercent),
          depositCommissionAmount: extractNumber(this.depositCommissionAmount),
          createDate: this.date,
        };
        this.showReport = false;
        let response = unifiedResponseHandler(
            await internal_transferService.pastInternalTransfer(form)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.clearFormFields();
        } else {
          this.error = true;
        }
      } else {
        console.log("not valid");
      }
    },

    handleKeyDown(event) {
      handleKeyDown(event);
    },
    clearSourceAccount() {
      this.sourceAccount = null;
      this.fromCurrency = null;
      this.XESourceToTargetRate = null;
      this.XETargetToSourceRate = null;
    },
    clearTargetAccount() {
      this.targetAccount = null;
      this.toCurrency = null;
      this.XESourceToTargetRate = null;
      this.XETargetToSourceRate = null;
    },
  },
  async mounted() {
    emitter.on("chosenAccount", (value) => {
      if (value.dir === "source") {
        this.sourceAccount = value;
        this.fromCurrency = this.sourceAccount.currency;
        this.sourceAccountListModal = false;
      }
      if (value.dir === "target") {
        this.targetAccount = value;
        this.toCurrency = this.targetAccount.currency;
        this.targetAccountListModal = false;
      }
      if (value.dir === "secondaryThirdPartyAccount") {
        this.secondaryThirdPartyAccount = value;
        this.secondaryThirdPartyAccountListModal = false;
      }
    });
  },

  computed: {
    ...mapGetters(["page"]),
    targetAccountBalance() {
      return this.targetAccount
          ? formatNumber(this.targetAccount.actualAmount)
          : null;
    },
    sourceAccountBalance() {
      return this.sourceAccount
          ? formatNumber(this.sourceAccount.actualAmount)
          : null;
    },
    moneyOption() {
      if (this.sourceAccount) {
        return MoneyOption(this.sourceAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    moneyOption2() {
      if (this.targetAccount) {
        return MoneyOption(this.targetAccount.currency.symbol);
      } else {
        return MoneyOption(null);
      }
    },
    secondaryThirdPartyAccountCofferName() {
      return this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.cofferName : null;
    },
    secondaryThirdPartyAccountId() {
      return this.secondaryThirdPartyAccount ? this.secondaryThirdPartyAccount.id : null;
    },
    bidAmount() {
      let exRate;
      if (this.sourceToTargetMustBeSeenExRate != null) {
        exRate = extractNumber(this.sourceToTargetMustBeSeenExRate);
      } else {
        exRate = extractNumber(this.targetToSourceMustBeSeenExRate);
      }
      const spreadPercentage = extractNumber(this.spreadValue) / 100;
      return exRate * (1 - spreadPercentage);
    },
    askAmount() {
      let exRate;
      if (this.sourceToTargetMustBeSeenExRate != null) {
        exRate = extractNumber(this.sourceToTargetMustBeSeenExRate);
      } else {
        exRate = extractNumber(this.targetToSourceMustBeSeenExRate);
      }
      const spreadPercentage = extractNumber(this.spreadValue) / 100;
      return exRate * (1 + spreadPercentage);
    },

    sourceAccountCofferName() {
      return this.sourceAccount ? this.sourceAccount.cofferName : null;
    },
    targetAccountCofferName() {
      return this.targetAccount ? this.targetAccount.cofferName : null;
    },

    sourceAccountId() {
      if (this.sourceAccount) {
        return this.sourceAccount.id;
      } else {
        return null;
      }
    },
    targetAccountId() {
      if (this.targetAccount) {
        return this.targetAccount.id;
      } else {
        return null;
      }
    },
    formatNumberInternalBidAmount() {
      if (this.bidAmount) {
        return formatNumberInternal(this.bidAmount);
      } else {
        return null;
      }
    },
    formatNumberInternalAskAmount() {
      if (this.askAmount) {
        return formatNumberInternal(this.askAmount);
      } else {
        return null;
      }
    },
  },
  watch: {
    sourceAccount() {
      if (this.sourceAccount && this.targetAccount) {
        this.isLoading = false;
        this.tickBid = false;
        this.tickAsk = false;
        this.getExchangeRate();
      }
    },
    targetAccount() {
      this.targetAccountListModal = false;
      if (this.sourceAccount && this.targetAccount) {
        this.isLoading = false;
        this.tickBid = false;
        this.tickAsk = false;
        this.getExchangeRate();
      }
    },
  },
  beforeUnmount() {
    this.$store.dispatch("setChosenInternalTransfer", null);
    this.$store.dispatch("setIsEditingInternalTransfer", false);
  },
};
</script>
