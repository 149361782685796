<template>
  <v-container v-if="!broken_page" fluid>
    <h3 class="font-weight-medium">Customers</h3>
    <v-row>
      <v-col>
        <v-text-field
            class="adjust-label search-field"
            label="Search"
            v-model="searchValue"
        ></v-text-field>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn
            color="button_color"
            @click="NewThirdPartyList()"
            icon="mdi-plus"
            class="ml-2"
        >
        </v-btn>
        <v-btn
            color="button_color"
            @click="QCustomer = true"
            icon="mdi-help"
            class="ml-2"
        >
        </v-btn>
        <v-btn color="button_color" icon="mdi-export" class="ml-2">
          <download-excel :data="customerList"
          >
            <v-icon>mdi-export</v-icon>
          </download-excel
          >
        </v-btn>
      </v-col>
    </v-row>

    <EasyDataTable
        :headers="headers"
        :items="customerList"
        item-title="name"
        :search-field="searchField"
        :search-value="searchValue"
        body-text-direction="center"
        header-text-direction="center"
        alternating
        border-cell
        class="custom-data-table"
        :loading="loading"
    >
      <template #item-edit="item">
        <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
      </template>
      <template #item-delete="item">
        <v-icon @click="deleteThirdParty(item)">mdi-delete</v-icon>
      </template>
      <template #item-creditLimit="{ creditLimit }">
        <div style="text-align: end">
          <span>{{ creditLimit }}</span>
        </div>
      </template>
    </EasyDataTable>
  </v-container>
  <div v-else>
    <Broken_Page_V01></Broken_Page_V01>
  </div>
  <Success_Modal
      :message="message"
      :success="success"
      @closeSuccessModal="success = false"
  ></Success_Modal>
  <Error_Modal
      :message="message"
      :error="error"
      @closeErrorModal="error = false"
  ></Error_Modal>

  <Confirmation_Modal
      :confirmation="confirmation"
      @DoConfirmation="getConfirmation()"
      @closeConfirmationModal="confirmation = false"
  ></Confirmation_Modal>

  <v-dialog v-model="QCustomer" width="auto">
    <Q_Customer_Modal_V01></Q_Customer_Modal_V01>
  </v-dialog>
</template>

<script>
import Q_Customer_Modal_V01 from "@/components/ModalQ/Q_Customer_Modal_V01.vue";
import {requiredRule} from "@/utils/rules.js";
import third_partyService from "@/services/third_party.service";
import customer_listService from "@/services/customer_list.service";
import {
  setPage,
  unifiedResponseHandler,
  formatNumber,
} from "@/utils/helpers";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import Success_Modal from "@/components/Utils/Modals/Success_Modal.vue";
import Error_Modal from "@/components/Utils/Modals/Error_Modal.vue";
import Confirmation_Modal from "@/components/Utils/Confirmation_Modal.vue";

export default {
  name: "customer_list_v01",
  components: {
    Confirmation_Modal,
    Error_Modal,
    Success_Modal,
    Broken_Page_V01,
    Q_Customer_Modal_V01,
  },
  data() {
    return {
      headers: [
        {text: "Account Number:", value: "accountNumber"},
        {text: "Customer Name:", value: "fullName"},
        {text: "Customer Limit:", value: "creditLimit"},
        {text: "Customer Email:", value: "email"},
        {text: "Customer Phone Number:", value: "phoneNumber"},
        {text: "Edit/Details", value: "edit"},
        {text: "Delete", value: "delete"},
      ],
      message: null,
      customerList: [],
      itemsSelected: [],
      searchField: "",
      searchValue: "",
      broken_page: false,
      success: false,
      error: false,
      requiredRule: requiredRule,
      customerName: null,
      AccountNumber: null,
      customerLimit: null,
      customerEmail: null,
      customerPhoneNumber: null,
      confirmation: false,
      confirmed: false,
      QCustomer: false,
      loading: false,
    };
  },
  methods: {
    formatNumber,
    async getCustomerList() {
      this.loading = true;
      let response = unifiedResponseHandler(
          await customer_listService.getCustomerList()
      );
      console.log(response);
      if (response.success) {
        this.loading = false;
        this.customerList = response.data;
        this.customerList = response.data.map((item) => ({
          ...item,
          creditLimit: this.formatNumber(item.creditLimit),
        }));
      } else {
        this.broken_page = true;
      }
    },
    NewThirdPartyList() {
      this.$store.dispatch("setIsEditingThirdParty", false);
      this.$store.dispatch("setChosenThirdParty", null);
      setPage("new_third_party");
    },
    goToEdit(third_party) {
      this.$store.dispatch("setChosenThirdParty", third_party);
      this.$store.dispatch("setIsEditingThirdParty", true);
      setPage("new_third_party");
    },
    getConfirmation() {
      this.confirmed = true;
      this.confirmation = false;
      return true;
    },
    waitForConfirmation() {
      return new Promise((resolve) => {
        const checkVariable = () => {
          if (!this.confirmation) {
            resolve();
          } else {
            setTimeout(checkVariable, 100);
          }
        };
        checkVariable();
      });
    },
    async deleteThirdParty(third_party) {
      this.confirmation = true;
      await this.waitForConfirmation();
      if (this.confirmed) {
        this.confirmed = false;
        let response = unifiedResponseHandler(
            await third_partyService.deleteThirdParty(third_party.id)
        );
        this.message = response.message;
        if (response.success) {
          this.success = true;
          this.getCustomerList();
        } else {
          this.error = true;
        }
      }
    },
  },
  async mounted() {
    await this.getCustomerList();
  },
};
</script>
