<template>
  <v-container v-if="!broken_page" fluid>
    <h5>Currency Database:</h5>
    <v-row >
      <v-col>
        <v-text-field
          class="adjust-label search-field"
          label="Search"
          v-model="searchValue"
        ></v-text-field>
      </v-col>
        <v-col class="d-flex justify-end">
          <v-btn color="button_color" @click="newCurrencyModal = true" icon="mdi-plus" class="ml-2">
            </v-btn>
            <v-btn color="button_color" icon="mdi-export" class=" ml-2">
              <download-excel :data="currencies" ><v-icon>mdi-export</v-icon></download-excel>
            </v-btn>
          </v-col>
    </v-row>
    <EasyDataTable
      :headers="headers"
      :items="currencies"
      :search-field="searchField"
      :search-value="searchValue"
      alternating
      border-cell
      class="custom-data-table"
    >
    <template #item-edit="item">
          <v-icon @click="goToEdit(item)">mdi-pencil</v-icon>
        </template>
        <template #item-delete="item">
          <v-icon @click="deleteCurrency(item)">mdi-delete</v-icon>
        </template>
    </EasyDataTable>
  </v-container>

  <div v-else>
      <Broken_Page_V01></Broken_Page_V01>
    </div>

    <v-dialog v-model="error">
    <div class="error-dialog">
      <div class="error-left">
        <div class="red-icon"></div>      
      </div>
      <div class="error-right">
      <span class="close-icon" @click="error = false" style="text-align: right;">✖</span>
      <h4>Error</h4>
    <p class="error-message">{{ message }} </p>
    <button class="close-button" @click="error = false">Close</button>
      </div>
    </div>
</v-dialog>

     <v-dialog v-model="success">
        <div class="success-dialog">
          <div class="success-left">
            <div class="green-circle">
              <svg width="180" height="180" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 100 L80 130 L150 50" stroke="white" stroke-width="25" fill="transparent" class="success-tick" />
          </svg>
        </div>      
          </div>
          <div class="success-right">
          <span class="close-icon" @click="success = false" style="text-align: right;">✖</span>
          <h4>Success</h4>
        <p class="success-message"> Successfully saved currency</p>
        <button class="success-close-button" @click="success = false">Close</button>
          </div>
        </div>
    </v-dialog> 
  <v-dialog v-model="newCurrencyModal" width="auto">
      <New_Currency_Modal_V01></New_Currency_Modal_V01>
    </v-dialog>
</template>

<script>
import currencyService from "@/services/currency.service";
import { unifiedResponseHandler } from "@/utils/helpers";
import New_Currency_Modal_V01 from "../../Modals/New_Currency_Modal_V01.vue"
import { requiredRule } from "@/utils/rules.js";
import Broken_Page_V01 from "../../Broken_Page_V01.vue";
import emitter from "@/plugins/mitt";


export default {
  name: "currency_database_v01",
  components: {
    Broken_Page_V01,
    New_Currency_Modal_V01

  },
  data() {
    return {
      headers: [
        { text: "Currency", value: "name" },
        { text: "Symbol", value: "symbol" },
        { text: "currency Code", value: "currencyCode" },
        { text: "Equivalent", value: "xeequivalent" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      currencies: [],
      itemsSelected: [],
      searchField: "",
      searchValue: "",
      broken_page: false,
      newCurrencyModal: false,
      success: false,
      error: false,
      requiredRule: requiredRule,
      countries: [],
      currencyName: null,
      currencyCode: null,
      currencySymbol: null,
      message: null,
    };
  },
  methods: {
    async getCurrency() {
      let response = unifiedResponseHandler(await currencyService.getCurrencies());
      console.log(response);

      if (response.success) {
        this.currencies = response.data;
        console.log("response.data",response.data)
      } else {
        this.broken_page = true;
      }
    },
    goToEdit(item) {
      this.$store.dispatch("setChosenCurrency", item);
      this.$store.dispatch("setIsEditingCurrency", true);
      this.newCurrencyModal = true;
    },

    async deleteCurrency(item) {
      let response = unifiedResponseHandler(
        await currencyService.deleteCurrency(item.id)
      );
      this.message = response.message;
      console.log("message147",response.message)
      if (response.success) {
        this.success = true;
        this.getCurrency();
      } else {
        this.error = true;
        this.message = response.message || "An error occurred"
      }
    },
  },
  async mounted() {
    await this.getCurrency();
    emitter.on("new_currency", (value) => {
      this.getCurrency();
      this.newCurrencyModal = false;
      this.message = value;
      console.log("value161",value)
      // this.success = true;
    });
  },
};
</script>
